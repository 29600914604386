// @mui material components
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
import MKTypography from "components/MKTypography";

import PropTypes from "prop-types";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function HeaderTwo({ title, desc, color, align }) {
  return (
    <Grid
      container
      item
      flexDirection="column"
      alignItems={align}
      xs={12}
      lg={6}
      sx={{ textAlign: "center", mx: "auto" }}
      data-aos="fade-up"
    >
      <MKTypography variant="h2" mb={1}>
        {title}
      </MKTypography>
      <MKTypography variant="body2" color={color}>
        {desc}
      </MKTypography>
    </Grid>
  );
}
HeaderTwo.defaultProps = {
  color: "text",
};

HeaderTwo.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  color: PropTypes.string,
  align: PropTypes.string.isRequired,
};

export default HeaderTwo;
