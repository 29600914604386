// import Container from "@mui/material/Container";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";
// import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Icon from "@mui/material/Icon";
// import MKButton from "components/MKButton";
// import contentOneCode from "layouts/sections/page-sections/content-sections/components/ContentOne/code";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function PartnersBlock({ title, content, width, opacity, boxHeight }) {
  return (
    <>
      <Grid
        container
        item
        flexDirection="column"
        alignItems="center"
        sx={{ textAlign: "center", mx: "auto" }}
        lg={10}
        xs={12}
        data-aos="fade-up"
      >
        <MKTypography variant="h3" mb={2}>
          {title}
        </MKTypography>
        <Grid container spacing={0} justifyContent="center" mb={0}>
          {content.map((object) => (
            <Grid item xs={6} md={4} lg={2} key={object.key} sx={{ mt: { xs: 2, lg: 0 } }}>
              <Box
                sx={{
                  height: boxHeight,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKBox
                  component="img"
                  src={object.src}
                  alt={object.alt}
                  width={width}
                  opacity={opacity}
                  sx={{
                    maxHeight: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    objectFit: "contain",
                  }}
                  key={object.key}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
PartnersBlock.defaultProps = {
  boxHeight: "3.5rem",
};
PartnersBlock.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  width: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  boxHeight: PropTypes.string,
};

export default PartnersBlock;
