// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import ListItem from "components/ListItem";
// import MKTypography from "components/MKTypography";

import PropTypes from "prop-types";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
function ContentBlockOne({ title, desc, contentImage, alignRight, isCard }) {
  return (
    <>
      {alignRight ? (
        <Grid
          container
          alignItems="center"
          sx={{ mt: 0 }}
          item
          lg={10}
          xs={12}
          mx="auto"
          align="center"
        >
          {/* <Grid mx="auto" align="center">
            <MKTypography variant="h2" data-aos="fade-up" mb={1}>
              {mainTitle}
            </MKTypography>
            <MKTypography variant="body2" data-aos="fade-up" mb={4}>
              {mainDescription}
            </MKTypography>
          </Grid> */}
          <Grid
            item
            xs={12}
            md={4}
            data-aos="fade-right"
            sx={{ ml: "auto" }}
            // data-aos-delay="200"
          >
            {/* use MKBox and PNG images */}
            {isCard ? (
              <Card
                sx={{
                  backgroundImage: `url(${contentImage})`,
                  height: { lg: "40vh", md: "25vh" },
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundColor: "transparent",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              />
            ) : (
              <MKBox
                borderRadius="lg"
                sx={{
                  backgroundImage: `url(${contentImage})`,
                  height: { lg: "40vh", md: "25vh" },
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundColor: "transparent",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              />
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            sx={{ mr: "auto", ml: { xs: 0, md: 6 }, textAlign: "center" }}
            data-aos="fade-left"
            flexDirection="column"
            alignItems="center"
            // data-aos-delay="200"
          >
            <ListItem title={title}>{desc}</ListItem>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          sx={{ mt: 0 }}
          item
          lg={10}
          xs={12}
          mx="auto"
          align="center"
        >
          {/* <Grid mx="auto" align="center">
            <MKTypography variant="h2" data-aos="fade-up" mb={1}>
              {mainTitle}
            </MKTypography>
            <MKTypography variant="body2" data-aos="fade-up" mb={4}>
              {mainDescription}
            </MKTypography>
          </Grid> */}
          <Grid
            container
            item
            xs={12}
            md={5}
            sx={{
              ml: "auto",
              mr: { xs: 0, md: 6 },
              textAlign: "center",
            }}
            data-aos="fade-right"
            flexDirection="column"
            alignItems="center"
            // xs={12}
            // lg={6}
            // sx={{ textAlign: "center", mx: "auto" }}
            // data-aos-delay="200"
          >
            <ListItem title={title}>
              {desc}
              {/* <Grid mt={5} /> */}
              {/* {t("PIM.Information.Block2.description.para2")} */}
            </ListItem>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: "auto" }}
            data-aos="fade-left"
            // data-aos-delay="200"
          >
            {isCard ? (
              <Card
                sx={{
                  backgroundImage: `url(${contentImage})`,
                  height: { lg: "40vh", md: "25vh" },
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundColor: "transparent",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              />
            ) : (
              <MKBox
                borderRadius="lg"
                sx={{
                  backgroundImage: `url(${contentImage})`,
                  height: { lg: "40vh", md: "25vh" },
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundColor: "transparent",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
ContentBlockOne.defaultProps = {
  isCard: false,
  alignRight: false,
};

ContentBlockOne.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  contentImage: PropTypes.string.isRequired,
  alignRight: PropTypes.bool,
  isCard: PropTypes.bool,
};

export default ContentBlockOne;
