/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import TransparentTeamCard from "components/TeamCards/TransparentTeamCard";

// Images

import PropTypes from "prop-types";

function TeamOne({ title, desc, people }) {
  return (
    <MKBox component="section">
      <Container>
        <Grid
          item
          flexDirection="column"
          container
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto" }}
          data-aos="fade-up"
        >
          <MKTypography variant="h3" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            {desc}
          </MKTypography>
        </Grid>
        <Grid
          container
          mt={3}
          width="60%"
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto" }}
        >
          {people.map((object) => (
            <Grid item xs={12} md={6} lg={4} data-aos="fade-left" key={object.key}>
              <TransparentTeamCard
                image={object.image}
                name={object.name}
                position={object.position}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}
TeamOne.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]))
  ).isRequired,
};

export default TeamOne;
