// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";

import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "components/InfoCards/DefaultInfoCard";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function ContentGridOne({
  title,
  desc,
  content,
  itemsFour,
  color,
  direction,
  type,
  width,
  withImageSide,
}) {
  return (
    <Grid container item flexDirection="column" alignItems="center">
      {itemsFour ? (
        <Grid
          container
          item
          flexDirection="column"
          xs={12}
          lg={width}
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item lg={5} xs={12}>
            <MKTypography variant="h2" data-aos="fade-up" mb={1}>
              {title}
            </MKTypography>
            <MKTypography variant="body2" data-aos="fade-up">
              {desc}
            </MKTypography>
          </Grid>
          <Grid container mt={2} data-aos="fade-up" data-aos-delay="200">
            {content.map((object) => (
              <Grid item xs={12} md={6} lg={3} key={object.key}>
                <DefaultInfoCard
                  color={color}
                  icon={object.icon}
                  title={object.title}
                  description={object.description}
                  direction={direction}
                  type={type}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <>
          {withImageSide ? (
            <>
              <Grid
                container
                item
                flexDirection="column"
                xs={12}
                lg={width}
                sx={{ textAlign: "center" }}
              >
                <MKTypography variant="h2" data-aos="fade-up">
                  {title}
                </MKTypography>
                <MKTypography variant="body2" data-aos="fade-up">
                  {desc}
                </MKTypography>
                <Grid container sx={{ mt: 2 }} data-aos="fade-up">
                  {content.map((object) => (
                    <Grid item xs={12} md={12} lg={4} key={object.key}>
                      <DefaultInfoCard
                        icon={object.icon}
                        title={object.title}
                        description={object.description}
                        direction={direction}
                        type={type}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              item
              flexDirection="column"
              xs={12}
              lg={width}
              sx={{ textAlign: "center" }}
            >
              <MKTypography variant="h2" data-aos="fade-up">
                {title}
              </MKTypography>
              <MKTypography variant="body2" data-aos="fade-up">
                {desc}
              </MKTypography>
              <Grid container sx={{ mt: 2 }} data-aos="fade-up">
                {content.map((object) => (
                  <Grid item xs={12} md={4} lg={4} key={object.key}>
                    <DefaultInfoCard
                      icon={object.icon}
                      title={object.title}
                      description={object.description}
                      direction={direction}
                      type={type}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
ContentGridOne.defaultProps = {
  itemsFour: false,
  title: " ",
  desc: "",
  type: "icon",
  width: 10,
  direction: "center",
  withImageSide: false,
};

ContentGridOne.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]))
  ).isRequired,
  color: PropTypes.string.isRequired,
  direction: PropTypes.string,
  itemsFour: PropTypes.bool,
  type: PropTypes.string,
  width: PropTypes.number,
  withImageSide: PropTypes.bool,
};

export default ContentGridOne;
