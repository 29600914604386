/* eslint-env browser */
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
// import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import AOS from "aos";
import "aos/dist/aos.css";

import PropTypes from "prop-types";

AOS.init();

function HeaderThree({ title, desc, bannerImage, buttonText, buttonLink }) {
  return (
    <MKBox
      minHeight="85vh"
      width="100%"
      sx={{
        backgroundImage: `url(${bannerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
      data-aos="fade-up"
    >
      <Grid
        container
        item
        xs={9}
        sm={8}
        md={7}
        lg={6}
        xl={5}
        xxl={4}
        justifyContent="center"
        mx="auto"
      >
        <MKTypography
          variant="h1"
          color="white"
          mt={-6}
          mb={1}
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],
            },
          })}
          textAlign="center"
          data-aos="fade-up"
        >
          {title}
        </MKTypography>
        {desc && (
          <MKTypography
            variant="body1"
            color="white"
            textAlign="center"
            mt={1}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {desc}
          </MKTypography>
        )}

        {buttonText && (
          <Grid
            container
            item
            xs={12}
            lg={11}
            justifyContent="center"
            mx="auto"
            mt={5}
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <MKButton component={Link} to={buttonLink} color="white">
              {buttonText}
            </MKButton>
          </Grid>
        )}
      </Grid>
    </MKBox>
  );
}

HeaderThree.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  bannerImage: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

export default HeaderThree;
