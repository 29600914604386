// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import MKButton from "components/MKButton";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";

import PropTypes from "prop-types";
import { useState } from "react";
import MKTypography from "components/MKTypography";
import FaqCollapse from "components/FaqCollapse";

import { useTranslation } from "react-i18next";

// Material Kit 2 PRO React components

// Sections components

// HelpCenter page component
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function FAQOne({ title }) {
  const [collapse, setCollapse] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} data-aos="fade-up">
          <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={2}>
            {title}
          </MKTypography>
          {/* <MKBox mb={2}>
            <MKTypography variant="body2" align="center" color="text">
              {desc}
            </MKTypography>
          </MKBox> */}
        </Grid>
        <Grid item xs={12} md={10} data-aos="fade-up" data-aos-delay="200">
          <FaqCollapse
            title={t("eCommerce.FAQ.question1.title")}
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            {t("eCommerce.FAQ.question1.description")}
          </FaqCollapse>
          <FaqCollapse
            title={t("eCommerce.FAQ.question2.title")}
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            {t("eCommerce.FAQ.question2.description")}
          </FaqCollapse>
          <FaqCollapse
            title={t("eCommerce.FAQ.question3.title")}
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            {t("eCommerce.FAQ.question3.description")}
          </FaqCollapse>
          <FaqCollapse
            title={t("eCommerce.FAQ.question4.title")}
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            {t("eCommerce.FAQ.question4.description")}
          </FaqCollapse>
        </Grid>
      </Grid>
    </>
  );
}

FAQOne.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FAQOne;
