// import Container from "@mui/material/Container";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";

import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function CTABlock({ bgImage, title, button, titleColor, buttonColor }) {
  const i18n = useTranslation();
  const baseRouteUrl = `/${i18n.i18n.language}`;
  return (
    <>
      <Grid
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="20px"
        p={6}
        sx={{
          backgroundImage: `url(${bgImage})`,
          mx: "auto",
        }}
        data-aos="fade-up"
        width="95%"
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h5" color={titleColor} fontWeight="bold">
              {title}
            </MKTypography>
          </Grid>
          <Grid item xs={6} sm={8} md={10} lg={6}>
            <MKBox sx={{ ml: { lg: "auto", sm: 0 }, width: "13rem" }}>
              <MKButton
                variant="gradient"
                color={buttonColor}
                fullWidth
                sx={{ boxShadow: "none" }}
                to={`${baseRouteUrl}/contact`}
                component={Link}
              >
                {button}
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

CTABlock.propTypes = {
  title: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
};

export default CTABlock;
