import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import HeaderFour from "components/HeaderFour/HeaderFour";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import ContentBlockOne from "components/ContentBlockOne/ContentBlockOne";
import TestimonialsLogo from "components/TestimonialLogo/TestimonialLogo";
import {
  IDEA_ICON,
  DEVELOPMENT2_ICON,
  LEARNING_ICON,
  STREAMING_HEADER_IMAGE,
  GIRL_GAMER_LOGO,
  CAPTURE_IMAGE,
  LIVE_IMAGE,
  TRANSCODE_IMAGE,
  TESTIMONIALS,
} from "commons/utils/constants";

import Card from "@mui/material/Card";
import i18n from "../../i18n";

function Streaming() {
  const { t } = useTranslation();
  const gridItems = [
    {
      icon: IDEA_ICON,
      title: i18n.t("streaming.intro.source.title"),
      description: i18n.t("streaming.intro.source.description"),
      key: 0,
    },
    {
      icon: DEVELOPMENT2_ICON,
      title: i18n.t("streaming.intro.endpoint.title"),
      description: i18n.t("streaming.intro.endpoint.description"),
      key: 1,
    },
    {
      icon: LEARNING_ICON,
      title: i18n.t("streaming.intro.solution.title"),
      description: i18n.t("streaming.intro.solution.description"),
      key: 2,
    },
  ];
  const people = [
    {
      image: TESTIMONIALS.fernando,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Helmet.Streaming")}</title>
        <meta name="description" content={t("Helmet.content.Streaming")} />
      </Helmet>
      <Grid>
        <HeaderFour
          title={t("streaming.mainBanner.title")}
          desc={t("streaming.mainBanner.description")}
          bannerImage={STREAMING_HEADER_IMAGE}
        />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          mt={5}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <ContentGridOne
            title={t("streaming.intro.title")}
            desc={t("streaming.intro.description")}
            content={gridItems}
            color="info"
            direction="center"
            type="image"
          />
        </Grid>
        <Grid mt={5}>
          <Grid>
            <ContentBlockOne
              title={t("streaming.mainContent.capture.title")}
              desc={t("streaming.mainContent.capture.description")}
              contentImage={CAPTURE_IMAGE}
              alignRight
            />
          </Grid>
          <Grid mt={2}>
            <ContentBlockOne
              title={t("streaming.mainContent.transcode.title")}
              desc={t("streaming.mainContent.transcode.description")}
              contentImage={TRANSCODE_IMAGE}
            />
          </Grid>
          <Grid mt={2}>
            <ContentBlockOne
              title={t("streaming.mainContent.live.title")}
              desc={t("streaming.mainContent.live.description")}
              contentImage={LIVE_IMAGE}
              alignRight
            />
          </Grid>
        </Grid>
        <Grid mt={8}>
          <TestimonialsLogo
            logo={GIRL_GAMER_LOGO}
            quote={t("streaming.testimonial.quote")}
            name={t("streaming.testimonial.name")}
            position={t("streaming.testimonial.position")}
            people={people}
          />
        </Grid>
      </Card>
    </>
  );
}

export default Streaming;
