import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useTranslation } from "react-i18next";
import HeaderThree from "components/HeaderThree/HeaderThree";
import { Helmet } from "react-helmet";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import { CONTACT_HEADER_IMAGE } from "commons/utils/constants";

// Services
import { submitContact } from "../../services/ContactService/contact-controller";

function ContactUs() {
  const { t } = useTranslation();

  const [isValid, setIsValid] = useState(false);
  const [contactEmail, setContactEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactMessage, setContactMessage] = useState("");

  // const [contacted, setContacted] = useState(false);

  const checker = (email, name, message) => {
    let valid = false;
    if (email && name && message) {
      valid = true;
    }
    return valid;
  };

  useEffect(() => {
    setIsValid(checker(contactEmail, contactName, contactMessage));
  }, [isValid, contactEmail, contactName, contactMessage]);

  const contactHandler = (event) => {
    event.preventDefault();
    // console.log(
    //   "contacted before",
    //   contacted,
    //   contactEmail,
    //   contactName,
    //   contactPhone,
    //   contactMessage
    // );
    const data = {
      email: contactEmail,
      name: contactName,
      phoneNumber: contactPhone,
      message: contactMessage,
    };
    submitContact(data)
      .then((res) => {
        console.log("SubmitContact - submitted", res);
        // setContacted(true);

        // history.push({
        //   pathname: "/contact-us",
        // });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Helmet>
        <title>{t("Helmet.Contact")}</title>
        <meta name="description" content={t("Helmet.content.ContactUs")} />
      </Helmet>
      <Grid data-aos="fade-down" data-aos-delay="300">
        <HeaderThree
          title={t("ContactUs.bannerTitle")}
          desc={t("")}
          buttonText={t("")}
          bannerImage={CONTACT_HEADER_IMAGE}
          buttonLink="/homepage"
        />
      </Grid>
      <Grid container item px={{ xl: 6 }} data-aos="fade-up" data-aos-delay="200">
        <MKBox
          width="100%"
          bgColor="white"
          borderRadius="xl"
          shadow="xl"
          mb={6}
          mt={5}
          sx={{
            overflow: "hidden",
            mx: { xs: 2, lg: 3 },
            mt: -15,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <MKBox component="form" p={2} method="post">
                <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                  <MKTypography variant="h2" mb={1}>
                    {t("ContactUs.leftBlock.title")}
                  </MKTypography>
                  <MKTypography variant="body1" color="text" mb={2}>
                    {t("ContactUs.leftBlock.subtitle")}
                  </MKTypography>
                </MKBox>
                <MKBox pt={0.5} pb={3} px={3}>
                  <Grid container>
                    <Grid item xs={12} pr={1} mb={3}>
                      <MKInput
                        variant="standard"
                        label={t("ContactUs.leftBlock.name.title")}
                        placeholder={t("ContactUs.leftBlock.name.placeholder")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={contactName}
                        onChange={(event) => setContactName(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} pr={1} mb={3}>
                      <MKInput
                        variant="standard"
                        label={t("ContactUs.leftBlock.email.title")}
                        placeholder={t("ContactUs.leftBlock.email.placeholder")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={contactEmail}
                        onChange={(event) => setContactEmail(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} pr={1} mb={3}>
                      <MKInput
                        variant="standard"
                        label={t("ContactUs.leftBlock.phoneNumber.title")}
                        placeholder={t("ContactUs.leftBlock.phoneNumber.placeholder")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={contactPhone}
                        onChange={(event) => setContactPhone(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} pr={1} mb={3}>
                      <MKInput
                        variant="standard"
                        label={t("ContactUs.leftBlock.message.title")}
                        placeholder={t("ContactUs.leftBlock.message.placeholder")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        multiline
                        rows={6}
                        value={contactMessage}
                        onChange={(event) => setContactMessage(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    justifyContent="flex-end"
                    textAlign="right"
                    ml="auto"
                  >
                    <MKButton
                      variant="gradient"
                      color="info"
                      disabled={!isValid}
                      onClick={contactHandler}
                    >
                      {t("ContactUs.leftBlock.button")}
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              position="relative"
              px={0}
              sx={{
                backgroundImage: ({
                  palette: { gradients },
                  functions: { rgba, linearGradient },
                }) =>
                  `${linearGradient(
                    rgba(gradients.info.main, 0.8),
                    rgba(gradients.info.state, 0.8)
                  )}, url(${bgImage})`,
                backgroundSize: "cover",
              }}
            >
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <MKBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                  <MKTypography variant="h3" color="white" mb={1}>
                    {t("ContactUs.rightBlock.title")}
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                    {t("ContactUs.rightBlock.subtitle")}
                  </MKTypography>
                  <MKBox display="flex" p={1}>
                    <MKTypography variant="button" color="white">
                      <i className="fas fa-phone" />
                    </MKTypography>
                    <MKTypography
                      component="span"
                      variant="button"
                      color="white"
                      opacity={0.8}
                      ml={2}
                      fontWeight="regular"
                    >
                      {t("ContactUs.rightBlock.phone")}
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" color="white" p={1}>
                    <MKTypography variant="button" color="white">
                      <i className="fas fa-envelope" />
                    </MKTypography>
                    <MKTypography
                      component="span"
                      variant="button"
                      color="white"
                      opacity={0.8}
                      ml={2}
                      fontWeight="regular"
                    >
                      {t("ContactUs.rightBlock.email")}
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex" color="white" p={1}>
                    <MKTypography variant="button" color="white">
                      <i className="fas fa-map-marker-alt" />
                    </MKTypography>
                    <MKTypography
                      component="span"
                      variant="button"
                      color="white"
                      opacity={0.8}
                      ml={2}
                      fontWeight="regular"
                    >
                      {t("ContactUs.rightBlock.address")}
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={3}>
                    <MKButton variant="text" color="white" size="large" iconOnly>
                      <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                    </MKButton>
                    <MKButton variant="text" color="white" size="large" iconOnly>
                      <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                    </MKButton>
                    <MKButton variant="text" color="white" size="large" iconOnly>
                      <i className="fab fa-linkedin" style={{ fontSize: "1.25rem" }} />
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </Grid>
    </>
  );
}

export default ContactUs;
