// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import PropTypes from "prop-types";

function HeaderFour({ title, desc, bannerImage }) {
  return (
    <MKBox component="header" position="relative">
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="85vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `
            ${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            lg={7}
            flexDirection="column"
            justifyContent="center"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {title}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
              {desc}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

HeaderFour.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  bannerImage: PropTypes.string.isRequired,
};

export default HeaderFour;
