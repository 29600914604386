import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import { useLocation, useNavigate } from "react-router-dom";

function LanguageToggle({ isDropdown }) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [light, setLight] = useState(false);

  // const [language, setLanguage] = useState(false);

  const handleLanguageChange = (lang) => {
    setLight(!light);

    if (location.pathname !== undefined) {
      const stupidPath = location.pathname.split("/").slice(2)[0];
      i18n.changeLanguage(lang);
      navigate(`${lang}/${stupidPath}`);
    }
  };

  return (
    <Grid>
      {isDropdown ? (
        <MKBox
          mx={1}
          display="flex"
          alignItems="baseline"
          sx={{ cursor: "pointer", userSelect: "none" }}
        >
          <Grid mx={2}>
            <Button
              onClick={() => handleLanguageChange("en")}
              style={{ maxHeight: "10px", maxWidth: "5px", minHeight: 0, minWidth: 0 }}
              sx={{ p: 1, mr: 1 }}
            >
              <MKTypography color={light ? "info" : "dark"} variant="caption">
                EN{" "}
              </MKTypography>
            </Button>
            <Button
              onClick={() => handleLanguageChange("cn")}
              style={{ maxHeight: "10px", maxWidth: "5px", minHeight: 0, minWidth: 0 }}
              sx={{ p: 1 }}
            >
              <MKTypography color={light ? "dark" : "info"} variant="caption">
                CN{" "}
              </MKTypography>
            </Button>
          </Grid>
        </MKBox>
      ) : (
        <Grid mt={0.5} mx={2}>
          <Button
            onClick={() => handleLanguageChange("en")}
            style={{ maxHeight: "10px", maxWidth: "5px", minHeight: 0, minWidth: 0 }}
            sx={{ p: 1, mr: 2 }}
          >
            <MKTypography variant="body2" color={light ? "white" : "dark"} fontWeight="regular">
              EN{" "}
            </MKTypography>
          </Button>
          <Button
            onClick={() => handleLanguageChange("cn")}
            style={{ maxHeight: "10px", maxWidth: "5px", minHeight: 0, minWidth: 0 }}
            sx={{ p: 1 }}
          >
            <MKTypography variant="body2" color={light ? "dark" : "white"} fontWeight="regular">
              CN{" "}
            </MKTypography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
LanguageToggle.defaultProps = {
  isDropdown: false,
};

// Typechecking props for the DefaultNavbarDropdown
LanguageToggle.propTypes = {
  isDropdown: PropTypes.bool,
};

export default LanguageToggle;
