// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import MKButton from "components/MKButton";i
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";

import PropTypes from "prop-types";
import SimpleInfoCard from "components/InfoCards/SimpleInfoCard";
// import MKTypography from "components/MKTypography";
import MKTypography from "components/MKTypography";
// import { Stack } from "@mui/material";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function ContentGridThree({ content, type, title, desc }) {
  return (
    <>
      <Grid
        flexDirection="column"
        alignItems="center"
        lg={6}
        item
        xs={12}
        sx={{ textAlign: "center", mx: "auto" }}
      >
        <MKTypography variant="h2" data-aos="fade-up" mb={1}>
          {title}
        </MKTypography>
        <MKTypography variant="body2" data-aos="fade-up" mb={4}>
          {desc}
        </MKTypography>
      </Grid>
      <Grid item xs={12} lg={10} alignItems="center" sx={{ mx: "auto" }}>
        <Grid container justifyContent="flex-start">
          {content.map((object) => (
            <Grid item xs={12} md={6} alignItems="center" mx="auto" data-aos="fade-up">
              <MKBox mb={4} sx={{ px: { md: 2, xs: 0 } }}>
                <SimpleInfoCard
                  icon={object.icon}
                  title={object.title}
                  description={object.description}
                  color="info"
                  type={type}
                  key={object.key}
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
        {console.log(content, type)}
      </Grid>
      {/* <Grid container item xs={12} justifyContent="center">
        <Grid
          container
          item
          flexDirection="column"
          xs={12}
          lg={10}
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <MKTypography variant="h2" data-aos="fade-up" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" data-aos="fade-up" mb={2}>
            {desc}
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          flexDirection="column"
          xs={12}
          lg={10}
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Stack sx={{ direction: { md: "row", xs: "column" } }} spacing={10}>
            <Stack direction="column" spacing={2}>
              {content1.map((object) => (
                <Grid sx={{ minHeight: { md: "15vh" } }}>
                  <SimpleInfoCard
                    icon={object.icon}
                    title={object.title}
                    description={object.description}
                    color="info"
                    type={type}
                    key={object.key}
                  />
                </Grid>
              ))}
            </Stack>
            <Stack direction="column" spacing={2} height="100%">
              {content2.map((object) => (
                <Grid sx={{ minHeight: { md: "15vh" } }}>
                  <SimpleInfoCard
                    icon={object.icon}
                    title={object.title}
                    description={object.description}
                    color="info"
                    type={type}
                    key={object.key}
                  />
                </Grid>
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid> */}
    </>
  );
}
ContentGridThree.defaultProps = {
  type: "icon",
  title: "",
  desc: "",
};

ContentGridThree.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,

  type: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default ContentGridThree;
