/* eslint-env browser */

import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Homepage from "layouts/pages/homepage";
// import routes from "routes";

// import footerRoutes from "footer.routes";
import Terms from "layouts/pages/terms";
import PrivacyTerms from "layouts/pages/privacy";
import { useTranslation } from "react-i18next";
import DefaultNavbar from "components/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";
import { BRAND_LOGO_WHITE, BRAND_LOGO_BLACK } from "commons/utils/constants";
// import { useTranslation } from "react-i18next";
import PIMPage from "layouts/pages/pim";
import ECommerePage from "layouts/pages/ecommerce";
import DevelopmentPage from "layouts/pages/development";
import CloudServicesPage from "layouts/pages/cloudservices";
import RapidCartPage from "pages/RapidCart";
import DrinkAgainPage from "pages/DrinkAgain";
// import ShopeeScoringPage from "pages/ShopeeScoring";
// import ShopeeGraphPage from "pages/ShopeeGraphs";
import StreamingPage from "pages/Streaming";
import WeimobPage from "pages/WeimobServices";
// import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
// import CreditCardIcon from "@mui/icons-material/CreditCard";
import ContactUsPage from "layouts/pages/contactus";
import AboutUsPage from "layouts/pages/aboutus";
import Footer from "components/Footer";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { LinkedIn } from "@mui/icons-material";
import ContactUs from "pages/ContactUs";
import MKTypography from "components/MKTypography";

const Layout = () => {
  const d = new Date();
  const year = d.getFullYear();
  const { pathname } = useLocation();
  const i18n = useTranslation();

  // console.log("layout - i18n", i18n);
  // console.log("layout - i18n language", i18n.i18n.language);
  // console.log("layout - pathname", pathname);

  const baseRouteUrl = `/${i18n.i18n.language}`;
  const baseUrl = ":/locale(en|cn)?";

  const routes = [
    {
      name: i18n.t("Header.item1"),
      icon: "",
      route: `${baseUrl}`,
      component: <Homepage />,
      key: 0,
    },
    {
      name: i18n.t("Header.products"),
      icon: "",
      collapse: [
        {
          name: i18n.t("Header.item2"),
          icon: "",
          route: `${baseRouteUrl}/pim`,
          component: <PIMPage />,
          key: 1,
        },
        {
          name: i18n.t("Header.cart"),
          icon: "",
          route: `${baseRouteUrl}/cart`,
          component: <RapidCartPage />,
          key: 2,
        },
        {
          name: i18n.t("Header.drinkAgain"),
          icon: "",
          route: `${baseRouteUrl}/drinkagain`,
          component: <DrinkAgainPage />,
          key: 2,
        },
        // {
        //   name: i18n.t("Header.shopeeScoring"),
        //   icon: "",
        //   route: `${baseRouteUrl}/shopeescoring`,
        //   component: <ShopeeScoringPage />,
        //   key: 2,
        // },
        // {
        //   name: i18n.t("Header.shopeeGraph"),
        //   icon: "",
        //   route: `${baseRouteUrl}/shopeegraph`,
        //   component: <ShopeeGraphPage />,
        //   key: 2,
        // },
        {
          name: i18n.t("Header.weimob"),
          icon: "",
          route: `${baseRouteUrl}/weimob`,
          component: <WeimobPage />,
          key: 5,
        },
      ],
    },
    {
      name: i18n.t("Header.services"),
      icon: "",
      collapse: [
        {
          name: i18n.t("Header.item3"),
          icon: "",
          route: `${baseRouteUrl}/ecommerce`,
          component: <ECommerePage />,
          key: 1,
        },
        {
          name: i18n.t("Header.item4"),
          icon: "",
          route: `${baseRouteUrl}/development`,
          component: <DevelopmentPage />,
          key: 2,
        },
        {
          name: i18n.t("Header.item5"),
          icon: "",
          route: `${baseRouteUrl}/cloudservices`,
          component: <CloudServicesPage />,
          key: 3,
        },
        {
          name: i18n.t("Header.streaming"),
          icon: "",
          route: `${baseRouteUrl}/streaming`,
          component: <StreamingPage />,
          key: 4,
        },
      ],
    },
    {
      name: i18n.t("Header.item6"),
      icon: "",
      route: `${baseRouteUrl}/about`,
      component: <AboutUsPage />,
      key: 5,
    },
    {
      name: i18n.t("Header.item7"),
      icon: "",
      route: `${baseRouteUrl}/contact`,
      component: <ContactUsPage />,
      key: 6,
    },
  ];
  const brand = [
    {
      image: BRAND_LOGO_BLACK,
      route: "/",
      title: i18n.t("Footer.content1"),
      subtitle: i18n.t("Footer.content2"),
    },
  ];
  const socials = [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/taotaroapp/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/TaoTaroApp",
    },
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/company/taotaro/",
    },
  ];
  const menus = [
    {
      name: "help & support",
      items: [
        {
          name: i18n.t("Footer.contact"),
          route: `${baseRouteUrl}/contact`,
          component: <ContactUs />,
          key: 0,
        },
      ],
    },
    {
      name: "terms",
      items: [{ name: i18n.t("Footer.terms"), route: "/terms", component: <Terms />, key: 1 }],
    },
    {
      name: "policy",
      items: [
        { name: i18n.t("Footer.policy"), route: "/privacy", component: <PrivacyTerms />, key: 2 },
      ],
    },
  ];
  const copyright = (
    <MKTypography variant="button" fontWeight="regular">
      <MKTypography
        component="a"
        href="https://beian.miit.gov.cn/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        粤ICP备2020124341号-1
      </MKTypography>
      &nbsp; ©{year} TaoTaro By TaoTaro Group Ltd.
    </MKTypography>
  );

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <>
      <DefaultNavbar routes={routes} brand={BRAND_LOGO_WHITE} light transparent />
      <Routes>
        {getRoutes(routes)}
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyTerms />} />

        <Route path={baseRouteUrl} element={<Homepage />} />
        {/* <Route path={`${baseUrl}/pim`} element={<PIMPage />} /> */}
        <Route path="*" element={<Navigate to={baseRouteUrl} />} />
      </Routes>
      <Footer brand={brand} socials={socials} menus={menus} copyright={copyright} />
    </>
  );
};
export default Layout;
