import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import HeaderFour from "components/HeaderFour/HeaderFour";
import {
  WEIMOB_HEADER_IMAGE,
  CLOUD_ICON,
  ECONOMIST_ICON,
  EXPERTISE_ICON,
  LICENSING_ICON,
  PREPARE_ICON,
  SHIP_ICON,
  SELL_ICON,
  PIM_FOOTER_IMAGE,
} from "commons/utils/constants";
import ContentGridFour from "components/ContentGridFour/ContentGridFour";
// import HeaderTwo from "components/HeaderTwo/HeaderTwo";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import ContentGridThree from "components/ContentGridThree/ContentGridThree";
import CTABlock from "components/CTABlock/CTABlock";

import Card from "@mui/material/Card";
import i18n from "../../i18n";

function WeimobServices() {
  const { t } = useTranslation();
  const about = [
    {
      icon: ECONOMIST_ICON,
      title: i18n.t("Weimob.About.Integrated.title"),
      description: i18n.t("Weimob.About.Integrated.description"),
      key: 0,
    },
    {
      icon: CLOUD_ICON,
      title: i18n.t("Weimob.About.wechat.title"),
      description: i18n.t("Weimob.About.wechat.description"),
      key: 1,
    },
    {
      icon: EXPERTISE_ICON,
      title: i18n.t("Weimob.About.brand.title"),
      description: i18n.t("Weimob.About.brand.description"),
      key: 2,
    },
  ];
  const gridItems = [
    {
      icon: LICENSING_ICON,
      title: i18n.t("Weimob.working.licensing.title"),
      description: i18n.t("Weimob.working.licensing.description"),
      key: 0,
    },
    {
      icon: PREPARE_ICON,
      title: i18n.t("Weimob.working.prepare.title"),
      description: i18n.t("Weimob.working.prepare.description"),
      key: 1,
    },
    {
      icon: SHIP_ICON,
      title: i18n.t("Weimob.working.ship.title"),
      description: i18n.t("Weimob.working.ship.description"),
      key: 2,
    },
    {
      icon: SELL_ICON,
      title: i18n.t("Weimob.working.sell.title"),
      description: i18n.t("Weimob.working.sell.description"),
      key: 3,
    },
  ];
  const gridItems1 = [
    {
      icon: "web",
      title: i18n.t("Weimob.services.mall.title"),
      description: i18n.t("Weimob.services.mall.description"),
      key: 0,
    },
    {
      icon: "headset_mic",
      title: i18n.t("Weimob.services.crm.title"),
      description: i18n.t("Weimob.services.crm.description"),
      key: 1,
    },
    {
      icon: "mobile_friendly",
      title: i18n.t("Weimob.services.tool.title"),
      description: i18n.t("Weimob.services.tool.description"),
      key: 2,
    },
    {
      icon: "sentiment_satisfied",
      title: i18n.t("Weimob.services.marketing.title"),

      description: i18n.t("Weimob.services.marketing.description"),
      key: 3,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Helmet.Weimob")}</title>
        <meta name="description" content={t("Helmet.content.Weimob")} />
      </Helmet>
      <Grid>
        <HeaderFour
          title={t("Weimob.mainBanner.title")}
          desc={t("Weimob.mainBanner.description")}
          bannerImage={WEIMOB_HEADER_IMAGE}
        />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid mt={5}>
          <ContentGridFour
            title={t("Weimob.About.title")}
            desc={t("Weimob.About.description")}
            content={about}
            type="image"
            buttonDisable
          />
        </Grid>
        <Grid mt={5}>
          <ContentGridOne
            content={gridItems}
            color="info"
            itemsFour
            direction="center"
            type="image"
            title={t("Weimob.working.title")}
            desc={t("Weimob.working.description")}
          />
        </Grid>
        <Grid mt={5}>
          <ContentGridThree
            content={gridItems1}
            isIcon={1}
            title={t("Weimob.services.title")}
            desc={t("Weimob.services.description")}
          />
        </Grid>
        <Grid mt={5}>
          <CTABlock
            bgImage={PIM_FOOTER_IMAGE}
            title={t("ContactUsBanner.title")}
            button={t("ContactUsBanner.button")}
            titleColor="white"
            buttonColor="warning"
          />
        </Grid>
      </Card>
    </>
  );
}

export default WeimobServices;
