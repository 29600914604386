// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKBadge from "components/MKBadge";
import MKAvatar from "components/MKAvatar";
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
// import SimpleReviewCard from "examples/Cards/ReviewCards/SimpleReviewCard";

// Images
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function TestimonialsLogo({ logo, quote, name, position, people }) {
  return (
    <MKBox
      variant="gradient"
      bgColor="dark"
      mx={-2}
      sx={{ maxHeight: { md: "60vh", lg: "90vh", xs: "65vh" } }}
      data-aos="fade-left"
    >
      <Grid container justifyContent="center" sx={{ py: 4, position: "relative", zIndex: 3 }}>
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
          <MKBox
            borderRadius="lg"
            sx={{
              backgroundImage: `url(${logo})`,
              height: { lg: "40vh", md: "25vh" },
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundColor: "transparent",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          />
          <MKTypography variant="h5" color="white" mt={3} mb={1} sx={{ px: { xs: 2, md: 0 } }}>
            &quot;{quote}&quot;
          </MKTypography>
          <MKTypography
            variant="subtitle1"
            color="white"
            fontWeight="bold"
            fontSize="medium"
            sx={{ mt: 2 }}
          >
            {name}
          </MKTypography>
          <MKTypography variant="subtitle2" color="white" fontWeight="light" fontSize="small">
            {position}
          </MKTypography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mx: "auto", alignSelf: "center" }}
        item
        alignItems="center"
        align="center"
        justifyContent="center"
      >
        {people.map((person) => (
          <Grid item mx={2} my={4}>
            <MKAvatar src={person.image} variant="rounded" size="sm" />
          </Grid>
        ))}
      </Grid>
    </MKBox>
  );
}
TestimonialsLogo.defaultProps = {
  position: "",
};

// Typechecking props for the DefaultReviewCard
TestimonialsLogo.propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  // date: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]))
  ).isRequired,
};

export default TestimonialsLogo;
