import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

import Features from "pages/PIM/sections/Features";
import ContentBlockOne from "components/ContentBlockOne/ContentBlockOne";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import HeaderOne from "components/HeaderOne/HeaderOne";
// import ScoreGenerator from "components/ScoreGenerator/ScoreGenerator";

import { Helmet } from "react-helmet";
import CTABlock from "components/CTABlock/CTABlock";
import {
  PIM_FOOTER_IMAGE,
  PIM_HEADER_IMAGE,
  COLLECT_ICON,
  MANAGE_ICON,
  ENRICH_ICON,
  DISTRIBUTE_ICON,
  CREDIT_CARD_ICON,
  DEVELOPER_ICON,
  WRITING_ICON,
  NONSTOP_ICON,
  LIFEBUOY_ICON,
  // PIM_MAIN_IMAGE,
  PIM_WHAT,
  PIM_WHY,
  PIM_MANAGE,
} from "commons/utils/constants";

import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function PIM() {
  const { t } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;
  const gridItems = [
    {
      icon: COLLECT_ICON,
      title: i18n.t("PIM.Working.item1.title"),
      description: i18n.t("PIM.Working.item1.description"),
      key: 0,
    },
    {
      icon: MANAGE_ICON,
      title: i18n.t("PIM.Working.item2.title"),
      description: i18n.t("PIM.Working.item2.description"),
      key: 1,
    },
    {
      icon: ENRICH_ICON,
      title: i18n.t("PIM.Working.item3.title"),
      description: i18n.t("PIM.Working.item3.description"),
      key: 2,
    },
    {
      icon: DISTRIBUTE_ICON,
      title: i18n.t("PIM.Working.item4.title"),
      description: i18n.t("PIM.Working.item4.description"),
      key: 3,
    },
  ];

  const features = [
    {
      icon: CREDIT_CARD_ICON,
      name: i18n.t("PIM.Features.feature1"),
      key: 0,
    },
    {
      icon: DEVELOPER_ICON,
      name: i18n.t("PIM.Features.feature2"),
      key: 1,
    },
    {
      icon: LIFEBUOY_ICON,
      name: i18n.t("PIM.Features.feature3"),
      key: 2,
    },
    {
      icon: WRITING_ICON,
      name: i18n.t("PIM.Features.feature4"),
      key: 3,
    },
    {
      icon: NONSTOP_ICON,
      name: i18n.t("PIM.Features.feature5"),
      key: 4,
    },
    {
      icon: NONSTOP_ICON,
      name: i18n.t("PIM.Features.feature6"),
      key: 5,
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("Helmet.PIM")}</title>
        <meta name="description" content={t("Helmet.content.PIM")} />
      </Helmet>
      <Grid>
        <HeaderOne
          title={t("PIM.mainBanner.title")}
          desc={t("PIM.mainBanner.description")}
          buttonText={t("PIM.mainBanner.button")}
          bannerImage={PIM_HEADER_IMAGE}
          buttonLink={`${baseRouteUrl}/contact`}
        />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <ScoreGenerator /> */}
        {/* <Grid>
          <ContentBlockOne
            title={t("PIM.Information.Block1.title")}
            desc={t("PIM.Information.Block1.description")}
            contentImage={PIM_INTRO}
            alignRight
          />
          <Divider sx={{ my: { xs: 2, sm: 4 }, mx: 12 }} />
          <ContentBlockOne
            title={t("PIM.Information.Block2.title")}
            desc={t("PIM.Information.Block2.description")}
            contentImage={PIM_WHY}
          />
        </Grid> */}
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          mt={5}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <ContentGridOne
            title={t("PIM.Working.title")}
            content={gridItems}
            color="info"
            itemsFour
            direction="center"
            type="image"
          />
        </Grid>
        <Grid mt={5}>
          <Grid>
            <ContentBlockOne
              title={t("PIM.Information.Block1.title")}
              desc={t("PIM.Information.Block1.description")}
              contentImage={PIM_WHAT}
              alignRight
            />
          </Grid>
          <Grid sx={{ mt: { md: 2, xs: 5 } }}>
            <ContentBlockOne
              title={t("PIM.Information.Block2.title")}
              desc={t("PIM.Information.Block2.description")}
              contentImage={PIM_WHY}
            />
          </Grid>
          <Grid sx={{ mt: { md: 2, xs: 5 } }}>
            <ContentBlockOne
              title={t("PIM.Information.Block3.title")}
              desc={t("PIM.Information.Block3.description")}
              contentImage={PIM_MANAGE}
              alignRight
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto", mt: 8 }}
        >
          <Features title={t("PIM.Features.title")} color="info" content={features} />
        </Grid>
        <Grid mt={8}>
          <CTABlock
            bgImage={PIM_FOOTER_IMAGE}
            title={t("PIM.ContactUsBanner.title")}
            button={t("PIM.ContactUsBanner.button")}
            titleColor="white"
            buttonColor="warning"
          />
        </Grid>
      </Card>
    </>
  );
}
export default PIM;
