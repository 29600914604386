/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

// @mui material components
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

function TransparentTeamCard({ image, name, position, description }) {
  return (
    <MKBox display="flex" flexDirection="column">
      <Grid
        container
        item
        flexDirection="column"
        alignItems="center"
        sx={{ textAlign: "center", mx: "auto" }}
        mt={3}
        mx={6}
      >
        <MKAvatar
          variant="rounded"
          size="xxl"
          src={image}
          alt={name}
          sx={{
            borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
            position: "relative",
            zIndex: 2,
          }}
        />
      </Grid>
      <Grid
        py={1}
        container
        item
        flexDirection="column"
        alignItems="center"
        sx={{ textAlign: "center", mx: "auto" }}
      >
        <MKTypography variant="h5">{name}</MKTypography>
        <MKTypography variant="body2" color="text">
          {position}
        </MKTypography>
        {description && (
          <MKTypography variant="body2" color="text" mb={2}>
            {description}
          </MKTypography>
        )}
      </Grid>
    </MKBox>
  );
}

// Setting default props for the TransparentTeamCard
TransparentTeamCard.defaultProps = {
  description: "",
};

// Typechecking props for the TransparentTeamCard
TransparentTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default TransparentTeamCard;
