// import Container from "@mui/material/Container";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";

import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CTABlockTwo({ title, button, color, desc }) {
  const i18n = useTranslation();
  const baseRouteUrl = `/${i18n.i18n.language}`;
  return (
    <>
      <Grid
        container
        item
        flexDirection="column"
        alignItems="center"
        xs={12}
        lg={6}
        sx={{ textAlign: "center", mx: "auto" }}
        data-aos="fade-up"
      >
        <MKTypography variant="h5">{title}</MKTypography>
        <MKTypography variant="body2" mt={2}>
          {desc}
        </MKTypography>
        <MKButton sx={{ mt: 5 }} color={color} to={`${baseRouteUrl}/contact`} component={Link}>
          {button}
        </MKButton>
      </Grid>
    </>
  );
}

CTABlockTwo.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default CTABlockTwo;
