import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

import MKTypography from "components/MKTypography";
// import FooterDropDown from "./FooterDropDown";

function Footer({ brand, socials, menus, copyright }) {
  return (
    <MKBox component="footer">
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto" }}
          mb={5}
        >
          {brand.map((object) => (
            <>
              <Link to={object.route}>
                <MKBox
                  component="img"
                  src={object.image}
                  alt=" "
                  maxWidth="10rem"
                  height="3vh"
                  m={2}
                />
              </Link>
              <MKTypography variant="body2" mb={2}>
                {object.title}
              </MKTypography>
              <MKTypography variant="body2" mb={2}>
                {object.subtitle}
              </MKTypography>
            </>
          ))}
          <MKBox display="flex" alignItems="center" mt={3}>
            {menus.map(({ name: title, items }) => (
              <Grid key={title} sx={{ mb: 3, mx: 2 }}>
                <MKBox sx={{ listStyle: "none" }}>
                  {items.map(({ name, route, href }) => (
                    <MKBox key={name}>
                      {href ? (
                        <MKTypography
                          component="a"
                          href={href}
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      )}
                    </MKBox>
                  ))}
                </MKBox>
              </Grid>
            ))}
          </MKBox>
          <MKBox display="flex" alignItems="center" mt={3}>
            {socials.map(({ icon, link }, key) => (
              <MKTypography
                key={link}
                component="a"
                href={link}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
                mr={key === socials.length - 1 ? 0 : 2.5}
              >
                {icon}
              </MKTypography>
            ))}
          </MKBox>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Footer.propTypes = {
  brand: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  socials: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  menus: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  copyright: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array]))
    .isRequired,
};

export default Footer;
