import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import HeaderFour from "components/HeaderFour/HeaderFour";
import {
  TEAM_HEADER_IMAGE,
  CONTENT1_IMAGE,
  LAUGH_ICON,
  EVOLUTION_ICON,
  CHALLENGES_ICON,
  GIFT_ICON,
  ECOMMERCE_FOOTER_IMAGE,
  TEAM,
} from "commons/utils/constants";

import ContentBlockTwo from "components/ContentBlockTwo/ContentBlockTwo";
import Card from "@mui/material/Card";
import CTABlock from "components/CTABlock/CTABlock";

import HeaderTwo from "components/HeaderTwo/HeaderTwo";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import TeamOne from "components/TeamOne/TeamOne";
import TimelineOne from "components/TimelineOne/TimelineOne";

// import ExecutiveTeam from "components/ExecutiveTeam/ExecutiveTeam";
import {
  Cloud,
  ThumbUp,
  RocketLaunch,
  Verified,
  ChangeCircle,
  MoreHoriz,
  Create,
} from "@mui/icons-material";
/* import CTABlockTwo from "components/CTABlockTwo/CTABlockTwo";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import TestimonialsOne from "components/TestimonialsOne/TestimonialsOne";

import {
  CLOUD_HEADER_IMAGE,
  CLOUD_ICON,
  ECONOMIST_ICON,
  EXPERTISE_ICON,
  MIGRATION_ICON,
  OPTIMIZE_ICON,
  PHONE_ICON,
  SECURITY_ICON,
} from "commons/utils/constants";
import HeaderOne from "components/HeaderOne/HeaderOne";
import HeaderTwo from "components/HeaderTwo/HeaderTwo";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import ContentGridFour from "components/ContentGridFour/ContentGridFour"; */
import i18n from "../../i18n";

function AboutUs() {
  const { t } = useTranslation();
  const info = [
    {
      para: i18n.t("AboutUs.Content1.para1"),
      key: 0,
    },
    {
      para: i18n.t("AboutUs.Content1.para2"),
      key: 1,
    },
    {
      para: i18n.t("AboutUs.Content1.para3"),
      key: 2,
    },
  ];
  const gridItems = [
    {
      icon: CHALLENGES_ICON,
      title: i18n.t("AboutUs.Content2.item1.title"),
      description: i18n.t("AboutUs.Content2.item1.description"),
      key: 0,
    },
    {
      icon: GIFT_ICON,
      title: i18n.t("AboutUs.Content2.item2.title"),
      description: i18n.t("AboutUs.Content2.item2.description"),
      key: 1,
    },
    {
      icon: EVOLUTION_ICON,
      title: i18n.t("AboutUs.Content2.item3.title"),
      description: i18n.t("AboutUs.Content2.item3.description"),
      key: 2,
    },
    {
      icon: LAUGH_ICON,
      title: i18n.t("AboutUs.Content2.item4.title"),
      description: i18n.t("AboutUs.Content2.item4.description"),
      key: 3,
    },
  ];
  const people = [
    {
      image: TEAM.nelson,
      name: i18n.t("AboutUs.People.person1.name"),
      position: i18n.t("AboutUs.People.person1.position"),
      key: 0,
    },
    {
      image: TEAM.edmund,
      name: i18n.t("AboutUs.People.person2.name"),
      position: i18n.t("AboutUs.People.person2.position"),
      key: 1,
    },
    {
      image: TEAM.kelvin,
      name: i18n.t("AboutUs.People.person3.name"),
      position: i18n.t("AboutUs.People.person3.position"),
      key: 2,
    },
    {
      image: TEAM.rafael,
      name: i18n.t("AboutUs.People.person4.name"),
      position: i18n.t("AboutUs.People.person4.position"),
      key: 3,
    },
    {
      image: TEAM.tamara,
      name: i18n.t("AboutUs.People.person5.name"),
      position: i18n.t("AboutUs.People.person5.position"),
      key: 4,
    },
    {
      image: TEAM.jacky,
      name: i18n.t("AboutUs.People.person6.name"),
      position: i18n.t("AboutUs.People.person6.position"),
      key: 5,
    },
    {
      image: TEAM.aurora,
      name: i18n.t("AboutUs.People.person7.name"),
      position: i18n.t("AboutUs.People.person7.position"),
      key: 6,
    },
    {
      image: TEAM.kavya,
      name: i18n.t("AboutUs.People.person8.name"),
      position: i18n.t("AboutUs.People.person8.position"),
      key: 7,
    },
    {
      image: TEAM.edward,
      name: i18n.t("AboutUs.People.person9.name"),
      position: i18n.t("AboutUs.People.person9.position"),
      key: 8,
    },
  ];
  const timeline = [
    {
      icon: <Create />,
      date: i18n.t("AboutUs.Timeline.item1.date"),
      title: i18n.t("AboutUs.Timeline.item1.title"),
      isImage: false,
      key: 0,
    },
    {
      icon: <ThumbUp />,
      date: i18n.t("AboutUs.Timeline.item2.date"),
      title: i18n.t("AboutUs.Timeline.item2.title"),
      isImage: false,
      key: 1,
    },
    {
      icon: <RocketLaunch />,
      date: i18n.t("AboutUs.Timeline.item3.date"),
      title: i18n.t("AboutUs.Timeline.item3.title"),
      isImage: false,
      key: 2,
    },
    {
      icon: <Verified />,
      date: i18n.t("AboutUs.Timeline.item4.date"),
      title: i18n.t("AboutUs.Timeline.item4.title"),
      isImage: false,
      key: 3,
    },
    {
      icon: <RocketLaunch />,
      date: i18n.t("AboutUs.Timeline.item5.date"),
      title: i18n.t("AboutUs.Timeline.item5.title"),
      isImage: false,
      key: 4,
    },
    {
      icon: <RocketLaunch />,
      date: i18n.t("AboutUs.Timeline.item6.date"),
      title: i18n.t("AboutUs.Timeline.item6.title"),
      isImage: false,
      key: 5,
    },
    {
      icon: <Cloud />,
      date: i18n.t("AboutUs.Timeline.item7.date"),
      title: i18n.t("AboutUs.Timeline.item7.title"),
      isImage: false,
      key: 6,
    },
    {
      icon: <ChangeCircle />,
      date: i18n.t("AboutUs.Timeline.item8.date"),
      title: i18n.t("AboutUs.Timeline.item8.title"),
      isImage: false,
      key: 7,
    },
    {
      icon: <ThumbUp />,
      date: i18n.t("AboutUs.Timeline.item9.date"),
      title: i18n.t("AboutUs.Timeline.item9.title"),
      isImage: false,
      key: 8,
    },
    {
      icon: <MoreHoriz />,
      title: i18n.t("AboutUs.Timeline.item10.title"),
      isImage: false,
      key: 9,
    },
  ];

  // const executiveTeam = [
  //   {
  //     image: TEAM.nelson,
  //     name: i18n.t("AboutUs.People.person1.name"),
  //     position: i18n.t("AboutUs.People.person1.position"),
  //     description: i18n.t("AboutUs.People.person1.description"),
  //     key: 0,
  //   },
  //   {
  //     image: TEAM.edmund,
  //     name: i18n.t("AboutUs.People.person2.name"),
  //     position: i18n.t("AboutUs.People.person2.position"),
  //     description: i18n.t("AboutUs.People.person2.description"),
  //     key: 1,
  //   },
  //   {
  //     image: TEAM.kelvin,
  //     name: i18n.t("AboutUs.People.person3.name"),
  //     position: i18n.t("AboutUs.People.person3.position"),
  //     description: i18n.t("AboutUs.People.person3.description"),
  //     key: 2,
  //   },
  //   {
  //     image: TEAM.rafael,
  //     name: i18n.t("AboutUs.People.person4.name"),
  //     position: i18n.t("AboutUs.People.person4.position"),
  //     description: i18n.t("AboutUs.People.person4.description"),
  //     key: 3,
  //   },
  // ];
  return (
    <>
      <Helmet>
        <title>{t("Helmet.AboutUs")}</title>
        <meta name="description" content={t("Helmet.content.AboutUs")} />
      </Helmet>
      <Grid>
        <HeaderFour
          title={t("AboutUs.mainBanner.title")}
          desc={t("AboutUs.mainBanner.description")}
          bannerImage={TEAM_HEADER_IMAGE}
        />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid sx={{ mt: { xs: 0, md: 5 } }}>
          <ContentBlockTwo
            title={t("AboutUs.Content1.title")}
            content={info}
            contentImage={CONTENT1_IMAGE}
          />
        </Grid>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto", mt: { md: 8, xs: 5 } }}
        >
          <HeaderTwo
            title={t("AboutUs.Content2.title")}
            desc={t("AboutUs.Content2.description")}
            align="center"
          />

          <ContentGridOne
            content={gridItems}
            color="info"
            itemsFour
            direction="center"
            type="image"
          />
        </Grid>

        {/* <Grid mt={5}>
          <ExecutiveTeam
            title={t("AboutUs.People.executiveTeam.title")}
            description={t("AboutUs.People.executiveTeam.description")}
            team={executiveTeam}
          />
        </Grid> */}
        <Grid mt={5}>
          <TeamOne
            title={t("AboutUs.People.title")}
            desc={t("AboutUs.People.description")}
            people={people}
          />
        </Grid>

        <Grid mt={8}>
          <Grid>
            <HeaderTwo
              title={t("AboutUs.Timeline.title")}
              desc={t("AboutUs.Timeline.description")}
              align="center"
            />
          </Grid>
          <Grid mt={4}>
            <TimelineOne content={timeline} />
          </Grid>
        </Grid>
        <Grid mt={8}>
          <CTABlock
            bgImage={ECOMMERCE_FOOTER_IMAGE}
            title={t("AboutUs.CTA.title")}
            button={t("AboutUs.CTA.button")}
            titleColor="white"
            buttonColor="warning"
          />
        </Grid>
      </Card>
    </>
  );
}

export default AboutUs;
