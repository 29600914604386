import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import FAQOne from "components/FAQOne/FAQOne";
import CTABlock from "components/CTABlock/CTABlock";

import { Helmet } from "react-helmet";
import ContentGridTwo from "components/ContentGridTwo/ContentGridTwo";
import ContentBlockOne from "components/ContentBlockOne/ContentBlockOne";
import HeaderOne from "components/HeaderOne/HeaderOne";
import { useTranslation } from "react-i18next";
import {
  ECOMMERCE_FOOTER_IMAGE,
  ECOMMERCE_HEADER_IMAGE,
  ECOMMERCE_DESC_IMAGE,
  API_ICON,
  DESIGN_ICON,
  GROWTH_ICON,
  PLATFORM_ICON,
  STRATEGY_ICON,
  SUPPORT_ICON,
} from "commons/utils/constants";
import HeaderTwo from "components/HeaderTwo/HeaderTwo";
import i18n from "../../i18n";

function ECommerce() {
  const { t } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;
  const gridItems = [
    {
      icon: (
        <MKBox
          sx={{
            backgroundImage: `url(${STRATEGY_ICON})`,
            height: "3vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "10vh",
            ml: 0,
          }}
        />
      ),
      title: i18n.t("eCommerce.Features.Block1.title"),
      description: i18n.t("eCommerce.Features.Block1.description"),
      key: 0,
    },
    {
      icon: (
        <MKBox
          sx={{
            backgroundImage: `url(${PLATFORM_ICON})`,
            height: "3vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "10vh",
            ml: 0,
          }}
        />
      ),
      title: i18n.t("eCommerce.Features.Block2.title"),
      description: i18n.t("eCommerce.Features.Block2.description"),
      key: 1,
    },
    {
      icon: (
        <MKBox
          sx={{
            backgroundImage: `url(${API_ICON})`,
            height: "3vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "10vh",
            ml: 0,
          }}
        />
      ),
      title: i18n.t("eCommerce.Features.Block3.title"),
      description: i18n.t("eCommerce.Features.Block3.description"),
      key: 2,
    },
    {
      icon: (
        <MKBox
          sx={{
            backgroundImage: `url(${DESIGN_ICON})`,
            height: "3vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "10vh",
            ml: 0,
          }}
        />
      ),
      title: i18n.t("eCommerce.Features.Block4.title"),
      description: i18n.t("eCommerce.Features.Block4.description"),
      key: 3,
    },
    {
      icon: (
        <MKBox
          sx={{
            backgroundImage: `url(${GROWTH_ICON})`,
            height: "3vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "10vh",
            ml: 0,
          }}
        />
      ),
      title: i18n.t("eCommerce.Features.Block5.title"),
      description: i18n.t("eCommerce.Features.Block5.description"),
      key: 4,
    },
    {
      icon: (
        <MKBox
          sx={{
            backgroundImage: `url(${SUPPORT_ICON})`,
            height: "3vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "10vh",
            ml: 0,
          }}
        />
      ),
      title: i18n.t("eCommerce.Features.Block6.title"),
      description: i18n.t("eCommerce.Features.Block6.description"),
      key: 5,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Helmet.Ecommerce")}</title>
        <meta name="description" content={t("Helmet.content.eCommerce")} />
      </Helmet>
      <Grid>
        <HeaderOne
          title={t("eCommerce.mainBanner.title")}
          desc={t("eCommerce.mainBanner.description")}
          buttonText={t("eCommerce.mainBanner.button")}
          bannerImage={ECOMMERCE_HEADER_IMAGE}
          buttonLink={`${baseRouteUrl}/contact`}
        />
      </Grid>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid mt={5}>
          <ContentGridTwo
            title={t("eCommerce.Features.title")}
            desc=""
            button={t("eCommerce.Features.button")}
            color="warning"
            content={gridItems}
          />
        </Grid>
        <Grid mt={5}>
          <Grid>
            <HeaderTwo
              title={t("eCommerce.Content.bannerTitle")}
              desc={t("eCommerce.Content.description")}
            />
          </Grid>
          <Grid mt={5}>
            <ContentBlockOne
              title={t("eCommerce.Content.subTitle")}
              desc={t("eCommerce.Content.subDescription")}
              contentImage={ECOMMERCE_DESC_IMAGE}
              alignRight
            />
          </Grid>
        </Grid>
        <Grid mt={8}>
          <FAQOne title={t("eCommerce.FAQ.title")} desc={t("eCommerce.FAQ.description")} />
        </Grid>

        <Grid mt={8}>
          <CTABlock
            bgImage={ECOMMERCE_FOOTER_IMAGE}
            title={t("ContactUsBanner.title")}
            button={t("ContactUsBanner.button")}
            titleColor="white"
            buttonColor="warning"
          />
        </Grid>
      </Card>
    </>
  );
}

export default ECommerce;
