// @mui material components
// import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
// import MKTypography from "components/MKTypography";
// import Card from "@mui/material/Card";
import PropTypes from "prop-types";
// import Container from "@mui/material/Container";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";

// import MKBox from "components/MKBox";
import AOS from "aos";
import "aos/dist/aos.css";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

AOS.init();

function ContentBlockThree({ title, content, contentImage, desc, type }) {
  return (
    <>
      {console.log(title, content, contentImage, desc, type)}

      <Grid item container lg={10} mx="auto" align="center">
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          justifyContent="center"
          mx="auto"
        >
          {/* <MKBox align="center" mx="auto" textAlign="center">
            <MKTypography variant="h2" mb={1}>
              {mainTitle}
            </MKTypography>
            <MKTypography variant="body2" color="text">
              {mainDescription}
            </MKTypography>
          </MKBox> */}
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            px={2}
            sx={{ display: { lg: "block", md: "block", xs: "none" } }}
          >
            <ColoredBackgroundCard image={contentImage} onlyImage />
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={8}
            sx={{ alignSelf: "center", px: { md: 2, xs: 0 } }}
          >
            <Grid sx={{ textAlign: { md: "left", xs: "left" }, width: { lg: "80%" } }}>
              <MKTypography variant="h2" mb={1} data-aos="fade-right">
                {title}
              </MKTypography>
              <MKTypography
                variant="subtitle2"
                color="text"
                data-aos="fade-right"
                data-aos-delay="200"
                mb={2}
              >
                {desc}
              </MKTypography>
            </Grid>
            <Grid data-aos="fade-right" data-aos-delay="200">
              <ContentGridOne
                content={content}
                width={12}
                color="info"
                direction="left"
                type={type}
                withImageSide
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid
        item
        container
        alignItems="center"
        sx={{ mx: { lg: "auto", xl: "auto", md: "auto", sm: 0 } }}
        lg={10}
        xs={12}
      >
        <Grid item ml={2}>
          <Card
            sx={{
              backgroundImage: `url(${contentImage})`,
              height: "50vh",
              width: "35vh",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: {
                sm: "none",
                xs: "none",
                md: "none",
                lg: "block",
              },
            }}
            data-aos="fade-up"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            mr: { lg: "auto", md: "auto", sm: 0, xs: 0 },
            mx: { lg: 0, md: 2, xs: 0 },
            px: { lg: 4, xs: 0 },
          }}
          my={6}
          justifyContent={{ md: "center" }}
        >
          <Grid sx={{ textAlign: { md: "left", xs: "center" } }}>
            <MKTypography variant="h2" mb={1} data-aos="fade-right">
              {title}
            </MKTypography>
            <MKTypography
              variant="subtitle2"
              color="text"
              mb={2}
              data-aos="fade-right"
              data-aos-delay="200"
            >
              {desc}
            </MKTypography>
          </Grid>
          <Grid data-aos="fade-right" data-aos-delay="200">
            <ContentGridOne
              content={content}
              width={12}
              color="info"
              direction="left"
              type={type}
            />
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
}
// ContentBlockThree.defaultProps = {
//   mainTitle: "",
//   mainDescription: "",
// };

ContentBlockThree.propTypes = {
  title: PropTypes.string.isRequired,
  contentImage: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  type: PropTypes.string.isRequired,
  // mainTitle: PropTypes.string,
  // mainDescription: PropTypes.string,
};

export default ContentBlockThree;
