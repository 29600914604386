// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

import PropTypes from "prop-types";

function TestimonialsOne({ title, desc, content }) {
  return (
    <>
      <Grid
        container
        item
        xs={12}
        lg={8}
        justifyContent="center"
        mx="auto"
        textAlign="center"
        data-aos="fade-up"
      >
        <Grid width="100%">
          <MKTypography variant="h2" mb={2}>
            {title}
          </MKTypography>
        </Grid>
        <MKTypography variant="body2" color="text">
          {desc}
        </MKTypography>
      </Grid>
      <Grid container sx={{ mt: { lg: 8, xs: 4 } }}>
        {content.map((object) => (
          <Grid
            item
            xs={12}
            md={8}
            lg={4}
            data-aos="fade-right"
            data-aos-delay="200"
            key={object.key}
            align="center"
            mx="auto"
          >
            <DefaultReviewCard
              image={object.image}
              name={object.name}
              // date="1 day ago"
              review={object.review}
              color={object.color}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

TestimonialsOne.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]))
  ).isRequired,
};

export default TestimonialsOne;
