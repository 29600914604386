import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CTABlock from "components/CTABlock/CTABlock";
// import MKBox from "components/MKBox";

import HeaderThree from "components/HeaderThree/HeaderThree";
import { Helmet } from "react-helmet";
import ContentGridThree from "components/ContentGridThree/ContentGridThree";

import PartnersBlock from "components/PartnersBlock/PartnersBlock";
// import bgImage from "assets/images/test-presentation3.png";
import {
  HOMEPAGE_FOOTER_IMAGE,
  HOMEPAGE_HEADER_IMAGE,
  HOMEPAGE_VALUES_IMAGE,
  PET_LOGO,
  YOGURTRAIN_LOGO,
  // JEBSEN_LOGO,
  CHILLAX_LOGO,
  // ZTORE_LOGO,
  PIM_ICON,
  DEVELOPMENT_ICON,
  ECOMMERCE_ICON,
  CLOUD2_ICON,
  HKSTP_INCUTECH_LOGO,
  PROVALIDATOR_LOGO,
  SECONDLIFE_LOGO,
  MERRYBIZ_LOGO,
} from "commons/utils/constants";
import { useTranslation } from "react-i18next";
import ContentBlockThree from "components/ContentBlockThree/ContentBlockThree";
// import VizSensor from "react-visibility-sensor";

import HeaderTwo from "components/HeaderTwo/HeaderTwo";
// import { useState } from "react";
// import { Fade, Slide } from "@mui/material";
import i18n from "../../i18n";

function Homepage() {
  const { t } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;

  // const [active, setActive] = useState(false);
  const gridItems1 = [
    {
      icon: PIM_ICON,
      title: i18n.t("Homepage.Information.Block1.title"),
      description: i18n.t("Homepage.Information.Block1.description"),
      key: 0,
    },
    {
      icon: DEVELOPMENT_ICON,
      title: i18n.t("Homepage.Information.Block2.title"),
      description: i18n.t("Homepage.Information.Block2.description"),
      key: 1,
    },
    {
      icon: ECOMMERCE_ICON,
      title: i18n.t("Homepage.Information.Block3.title"),
      description: i18n.t("Homepage.Information.Block3.description"),
      key: 2,
    },
    {
      icon: CLOUD2_ICON,
      title: i18n.t("Homepage.Information.Block4.title"),
      description: i18n.t("Homepage.Information.Block4.description"),
      key: 3,
    },
  ];

  const values = [
    {
      icon: "groups",
      title: i18n.t("Homepage.Values.Block1.title"),
      description: i18n.t("Homepage.Values.Block1.description"),
      key: 0,
    },
    {
      icon: "bolt",
      title: i18n.t("Homepage.Values.Block2.title"),
      description: i18n.t("Homepage.Values.Block2.description"),
      key: 1,
    },
    {
      icon: "timeline",
      title: i18n.t("Homepage.Values.Block3.title"),
      description: i18n.t("Homepage.Values.Block3.description"),
      key: 2,
    },
  ];
  const customers = [
    {
      src: PET_LOGO,
      alt: "pet-project",
      key: 0,
    },
    {
      src: PROVALIDATOR_LOGO,
      alt: "provalidator",
      key: 1,
    },
    {
      src: YOGURTRAIN_LOGO,
      alt: "yogurtrain",
      key: 2,
    },
    {
      src: CHILLAX_LOGO,
      alt: "chillax",
      key: 3,
    },
    {
      src: MERRYBIZ_LOGO,
      alt: "merrybiz",
      key: 4,
    },
    {
      src: SECONDLIFE_LOGO,
      alt: "2ndlife",
      key: 5,
    },
  ];
  const suppoters = [
    {
      src: HKSTP_INCUTECH_LOGO,
      alt: "incutech-hkstp",
      key: 0,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Helmet.Homepage")}</title>
        <meta name="description" content={t("Helmet.content.Homepage")} />
      </Helmet>
      <Grid>
        <HeaderThree
          title={t("Homepage.mainBanner.title")}
          desc={t("Homepage.mainBanner.description")}
          buttonText={t("Homepage.mainBanner.button")}
          x
          bannerImage={HOMEPAGE_HEADER_IMAGE}
          buttonLink={`${baseRouteUrl}/contact`}
        />
      </Grid>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid mt={2}>
          <ContentGridThree content={gridItems1} type="image" />
        </Grid>

        <Grid mt={5}>
          <Grid>
            <HeaderTwo title={t("Homepage.Motto.title")} desc={t("Homepage.Motto.description")} />
          </Grid>
          <Grid mt={5}>
            <ContentBlockThree
              title={t("Homepage.Values.title")}
              content={values}
              contentImage={HOMEPAGE_VALUES_IMAGE}
              type="icon"
            />
          </Grid>
        </Grid>

        <Grid mt={8}>
          <PartnersBlock
            title={t("Homepage.Customers.title")}
            content={customers}
            width="80%"
            opacity={0.8}
          />
        </Grid>
        <Grid mt={8}>
          <PartnersBlock
            title={t("Homepage.Supporters.title")}
            content={suppoters}
            width="50vh"
            boxHeight="8rem"
            opacity={0.8}
          />
        </Grid>
        <Grid mt={10}>
          <CTABlock
            bgImage={HOMEPAGE_FOOTER_IMAGE}
            title={t("ContactUsBanner.title")}
            button={t("ContactUsBanner.button")}
            titleColor="white"
            buttonColor="warning"
          />
        </Grid>
      </Card>
    </>
  );
}

export default Homepage;
