import Card from "@mui/material/Card";
import HeaderOne from "components/HeaderOne/HeaderOne";
import HeaderTwo from "components/HeaderTwo/HeaderTwo";
import Grid from "@mui/material/Grid";

import { Helmet } from "react-helmet";
import ContentGridThree from "components/ContentGridThree/ContentGridThree";
import ContentBlockTwo from "components/ContentBlockTwo/ContentBlockTwo";
import PartnersBlock from "components/PartnersBlock/PartnersBlock";
import { useTranslation } from "react-i18next";
import CTABlockTwo from "components/CTABlockTwo/CTABlockTwo";
import {
  DEVELOPMENT_HEADER_IMAGE,
  DEVELOPMENT_PROCESS_IMAGE,
  YUEHWA_LOGO,
  EEVY_LOGO,
  LOTUS_LOGO,
  PROVALIDATOR_LOGO,
} from "commons/utils/constants";
import i18n from "../../i18n";

function Development() {
  const { t } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;
  const customers = [
    {
      src: YUEHWA_LOGO,
      alt: "yuahwa",
      key: 0,
    },
    {
      src: LOTUS_LOGO,
      alt: "rising-lotus",
      key: 1,
    },
    {
      src: EEVY_LOGO,
      alt: "eevy-design",
      key: 2,
    },
    {
      src: PROVALIDATOR_LOGO,
      alt: "provalidator",
      key: 3,
    },
  ];

  const gridItems1 = [
    {
      icon: "web",
      title: i18n.t("Development.Information.item1.title"),
      description: i18n.t("Development.Information.item1.description"),
      key: 0,
    },
    {
      icon: "headset_mic",
      title: i18n.t("Development.Information.item2.title"),
      description: i18n.t("Development.Information.item2.description"),
      key: 1,
    },
    {
      icon: "mobile_friendly",
      title: i18n.t("Development.Information.item3.title"),
      description: i18n.t("Development.Information.item3.description"),
      key: 2,
    },
    {
      icon: "sentiment_satisfied",
      title: i18n.t("Development.Information.item4.title"),
      description: i18n.t("Development.Information.item4.description"),
      key: 3,
    },
  ];

  const info = [
    {
      subheading: i18n.t("Development.Information2.subheading1"),
      para: i18n.t("Development.Information2.para1"),
      key: 0,
    },
    {
      subheading: i18n.t("Development.Information2.subheading2"),
      para: i18n.t("Development.Information2.para2"),
      key: 1,
    },
    {
      subheading: i18n.t("Development.Information2.subheading3"),
      para: i18n.t("Development.Information2.para3"),
      key: 2,
    },
  ];
  return (
    <>
      <Helmet>
        <title>{t("Helmet.Development")}</title>
        <meta name="description" content={t("Helmet.content.Development")} />
      </Helmet>
      <Grid>
        <HeaderOne
          title={t("Development.mainBanner.title")}
          desc={t("Development.mainBanner.description")}
          buttonText={t("Development.mainBanner.button")}
          bannerImage={DEVELOPMENT_HEADER_IMAGE}
          buttonLink={`${baseRouteUrl}/contact`}
        />
      </Grid>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Grid>
          <HeaderTwo
            title={t("Development.header.title")}
            desc={t("Development.header.description")}
            align="center"
          />
        </Grid> */}
        <Grid mt={5}>
          <ContentGridThree
            content={gridItems1}
            isIcon={1}
            title={t("Development.header.title")}
            desc={t("Development.header.description")}
          />
        </Grid>
        <Grid>
          <Grid mt={5}>
            <HeaderTwo
              title={t("Development.Process.title")}
              desc={t("Development.Process.description")}
              align="center"
            />
          </Grid>
          <Grid mt={2}>
            <ContentBlockTwo
              title={t("Development.Information2.title")}
              content={info}
              contentImage={DEVELOPMENT_PROCESS_IMAGE}
            />
          </Grid>
        </Grid>
        <Grid mt={8}>
          <PartnersBlock
            title={t("Homepage.Customers.title")}
            content={customers}
            width="80%"
            opacity={0.8}
          />
        </Grid>
        <Grid mt={8} mb={2}>
          <CTABlockTwo
            title={t("Development.Footer.title")}
            desc={t("Development.Footer.description")}
            button={t("Development.Footer.button")}
            color="warning"
          />
        </Grid>
      </Card>
    </>
  );
}

export default Development;
