import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import { Grid } from "@mui/material";

function TimelineOne({ content }) {
  return (
    <Grid width="100%">
      <Timeline position="alternate">
        {content.map((object) => (
          <TimelineItem data-aos="fade-right" data-aos-delay="300" key={object.key}>
            <TimelineOppositeContent
              align="right"
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: { xs: 12, md: 15 }, m: "auto 0" }}
            >
              {object.date}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              {object.isImage ? (
                <Grid data-aos="fade-up" data-aos-delay="400">
                  <MKBox
                    sx={{
                      backgroundImage: `url(${object.icon})`,
                      height: "5vh",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "5vh",
                      ml: 0,
                    }}
                  />
                </Grid>
              ) : (
                <TimelineDot data-aos="fade-up" data-aos-delay="400">
                  {object.icon}
                </TimelineDot>
              )}

              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px" }} data-aos="fade-left" data-aos-delay="300">
              <Typography
                variant="subtitle2"
                sx={{ fontSize: { xs: 12, md: 15 } }}
                component="span"
              >
                {object.title}
              </Typography>
              {object.desc && <Typography>{object.desc}</Typography>}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Grid>
  );
}

TimelineOne.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node, PropTypes.bool])
    )
  ).isRequired,
};

export default TimelineOne;
