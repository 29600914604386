// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import MKButton from "components/MKButton";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";

import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import AOS from "aos";
import "aos/dist/aos.css";
// import i18n from "../../i18n";

AOS.init();
// const con = [
//   {
//     icon: "web",
//     title: i18n.t("Weimob.services.mall.title"),
//     description: i18n.t("Weimob.services.mall.description"),
//     key: 0,
//   },
//   {
//     icon: "headset_mic",
//     title: i18n.t("Weimob.services.crm.title"),
//     description: i18n.t("Weimob.services.crm.description"),
//     key: 1,
//   },
//   {
//     icon: "mobile_friendly",
//     title: i18n.t("Weimob.services.tool.title"),
//     description: i18n.t("Weimob.services.tool.description"),
//     key: 2,
//   },
//   {
//     icon: "sentiment_satisfied",
//     title: i18n.t("Weimob.services.marketing.title"),

//     description: i18n.t("Weimob.services.marketing.description"),
//     key: 3,
//   },
//   {
//     icon: "mobile_friendly",
//     title: i18n.t("Weimob.services.tool.title"),
//     description: i18n.t("Weimob.services.tool.description"),
//     key: 2,
//   },
//   {
//     icon: "sentiment_satisfied",
//     title: i18n.t("Weimob.services.marketing.title"),

//     description: i18n.t("Weimob.services.marketing.description"),
//     key: 3,
//   },
// ];

function ContentGridTwo({ title, desc, content }) {
  return (
    <>
      {console.log(title, desc, content)}
      <MKBox component="section">
        <Grid
          flexDirection="column"
          alignItems="center"
          lg={5}
          item
          xs={12}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" data-aos="fade-up" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" data-aos="fade-up" mb={4}>
            {desc}
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={10} alignItems="center" sx={{ mx: "auto" }}>
          <Grid container justifyContent="flex-start">
            {content.map((object) => (
              <Grid item xs={12} md={4} alignItems="center" mx="auto" data-aos="fade-up">
                <MKBox mb={4} sx={{ px: { md: 2, xs: 0 } }}>
                  <SimpleInfoCard
                    icon={object.icon}
                    title={object.title}
                    description={object.description}
                    color="info"
                    type="icon"
                    key={object.key}
                  />
                </MKBox>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </MKBox>

      {/* <Grid container item alignItems="center" xs={12} lg={10} sx={{ mx: "auto" }}>
        <Grid item xs={12} lg={5} mt={6} data-aos="fade-up">
          <MKTypography variant="h2" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" mb={2}>
            {desc}
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={7} textAlign="right">
          {/* <MKButton variant="gradient" color={color}>
            {button}
          </MKButton> */}
      {/* </Grid>
      </Grid>
      <Grid container item alignItems="center" xs={12} lg={10} sx={{ mx: "auto" }} mt={6}>
        {content.map((object) => (
          <Grid
            item
            xs={12}
            md={6}
            sm={6}
            lg={4}
            data-aos="fade-right"
            data-aos-delay="300"
            minHeight="200px"
            sx={{ display: "inline-block", verticalAlign: "top", alignItems: "flex-end" }}
            key={object.key}
          >
            <DefaultInfoCard
              icon={object.icon}
              title={object.title}
              description={object.description}
              small
            />
          </Grid>
        ))}
      </Grid> */}
    </>
  );
}

ContentGridTwo.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]))
  ).isRequired,
  desc: PropTypes.string.isRequired,
  // color: PropTypes.string.isRequired,
  // button: PropTypes.string.isRequired,
};

export default ContentGridTwo;
