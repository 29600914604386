// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import PropTypes from "prop-types";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function HeaderOne({ title, desc, bannerImage, buttonText, buttonLink, isButton }) {
  return (
    <MKBox
      component="header"
      position="relative"
      // width="100%"
      sx={{ overflowX: "hidden" }}
    >
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="85vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `
            ${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            lg={7}
            flexDirection="column"
            justifyContent="center"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {title}
            </MKTypography>
            <MKTypography
              color="white"
              opacity={0.8}
              pr={6}
              mr={6}
              variant="body1"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size.md,
                },
              })}
            >
              {desc}
            </MKTypography>
            {isButton ? (
              <>
                {" "}
                <Stack direction="row" spacing={1} mt={3}>
                  <MKButton component={Link} to={buttonLink} color="white">
                    {buttonText}
                  </MKButton>
                </Stack>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}
HeaderOne.defaultProps = {
  isButton: false,
};

HeaderOne.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  bannerImage: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
};

export default HeaderOne;
