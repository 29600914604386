/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
// import BaseLayout from "layouts/sections/components/BaseLayout";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function PrivacyTerms() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("Helmet.Homepage")}</title>
        <meta name="description" content="test_content" />
      </Helmet>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mb: 4,
          backgroundColor: ({ palette: { black }, functions: { rgba } }) => rgba(black.main, 0.8),
          height: "15vh",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 1,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h5" color="black" textAlign="left" mt={1}>
          Section 1- Collection of Information
        </MKTypography>
        <MKTypography variant="body1" color="black" textAlign="left" mt={1}>
          The personal information that we may collect about you broadly falls into the following
          categories
        </MKTypography>
        <MKTypography variant="h4" color="black" textAlign="left" mt={4} mx={2}>
          Information you provide to us
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          <li>
            You will be asked to provide certain contact information that is necessary for the
            registering for an account on our platform including name, address, phone number, email
            address
          </li>
          <li>
            You will be asked to provide certain identity information, tax registration and/or
            related information about your business, such as your company name, business type and
            industry, corporate registration details and information about your business license;
          </li>
          <li>
            We will also ask you to provide details about the goods and products that you intend to
            sell via the store built on our platform and details about your sales/transactions.
          </li>
          <li>
            In connection with the facilitation of transactions, purchases and payments over the
            Platform, you will be asked to provide certain payment information. This might include
            bank account numbers, billing and delivery information, credit/debit card numbers,
            expiration dates and security code and tracking information from cheques or money orders
            to facilitate the sale and purchase as well as the settlement of purchase price of the
            products or services transacted on or procured through the Platform;
          </li>
          <li>
            Information you submit to the Platform for publication may be made publicly available on
            the Platform and therefore accessible by any internet user. You should exercise caution
            when deciding what information you submit to us for publication.
          </li>
        </MKTypography>
        <MKTypography variant="h4" color="black" textAlign="left" mt={4} mx={2}>
          Information that we collect automatically
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          <li>
            If you contact us (such as via our customer services department chat service, telephone
            or email), we may record a copy of your correspondence and may ask for additional
            information to verify your identity.
          </li>

          <li>
            We will collect contact details and other information of users from publicly available
            sources for the purposes of verifying the users and performing due diligence on the
            users.
          </li>

          <li>
            We will collect details of user activities, transactions and interactions on the
            Platform including information relating to the types and specifications of the products
            and services purchased, pricing and delivery information, dispute and complaint records,
            communications between users and any information disclosed in any discussion forum.
          </li>

          <li>
            From time to time, we may also collect information about the existing and prospective
            users, during or in connection with trade shows, industry events and/or other functions.
            Including representative contact names, addresses, phone numbers, fax numbers and email
            addresses.
          </li>

          <li>
            If you visit our Platform, we will automatically collect certain information from your
            device. In some countries, including countries in the European Economic Area, this
            information may be considered as personal information under applicable data protection
            laws. Specifically this information may include IP addresses, device type, unique device
            identification numbers, browser type, broad geographic location (e.g. country or
            city-level location), browsing patterns and details of how you have interacted with our
            Platform and the goods and services available on it. In addition, we gather statistical
            information about the Platform and visitors to the Platform including, browser software,
            operating system, software and hardware attributes, pages viewed, number of sessions and
            unique visitors. Collecting this information enables us to better understand the
            visitors who come to our Platform, where they come from, and what content is of interest
            to them. We use this information for our internal analytics purposes and to improve the
            quality and relevance of our Platform. Some of this information may be collected using
            cookies and similar tracking technology
          </li>
        </MKTypography>
        <MKTypography variant="h4" color="black" textAlign="left" mt={4} mx={2}>
          Information that we recieve from third parties
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          We may receive personal information about you from social media platforms if you choose to
          register for a Platform account via a social media account. Exactly what information we
          receive will depend on your privacy settings with the applicable platform, but it would
          typically include basic public profile information such as:
          <li>Your user name or nickname</li>
          <li>Your profile picture</li>
          <li>Country </li>
          <li>Company name</li>
          <li>Contact details</li>
          We may receive personal information about you from third parties that are engaged by us to
          assist with providing verification services, and conducting suitable money laundering and
          KYC (know-your-customer) checks on users, and to improve our marketing efforts. This may
          include your name, email address, company details and contact information for company
          representatives.
        </MKTypography>
        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          Section 2- Use of Information
        </MKTypography>
        <MKTypography variant="h4" color="black" textAlign="left" mt={2} mx={2}>
          We collect and use your personal information for the following purposes:
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          <li>
            Verifying your identity and conducting security, KYC (know-your-customer) and anti-money
            laundering diligence and background checks in order to set up user accounts;
          </li>
          <li>
            Verifying your eligibility to register as a Platform user in accordance with our Terms
            of Use
          </li>
          <li>
            Setting up / administering user accounts, including providing log-in IDs and developing
            a Platform profile;
          </li>
          <li>
            Setting up / administering Official Wechat accounts. Wechat requires specific documents
            by their own third party verifier, only required documents will be shared with them for
            the purposes of account opening only.{" "}
          </li>
          <li>
            Assessing and monitoring account security and transaction risks of users of TaoTaro.app,
            detecting and preventing fraud, money laundering and other security incidents;
          </li>
          <li>
            Performing research or statistical analysis in order to improve the content and layout
            of the Platform, and to improve the product offerings and services on TaoTaro.app,
            including, for example, using anonymized data for machine learning purposes;
          </li>
          <li>
            Identifying, developing and marketing and advertising products and services that we
            believe you will value, including across browsers and devices, in accordance with
            applicable laws. Cookies or other similar technologies may be used to provide you with
            advertising based upon your browsing activities and interests. Where we are required by
            applicable law, we will seek your consent prior to sending you communications for
            marketing purposes;
          </li>
        </MKTypography>
        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          Section 3- CONSENT
        </MKTypography>
        <MKTypography variant="h4" color="black" textAlign="left" mt={2} mx={2}>
          How do you get my consent?
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          When you provide us with personal information to complete a transaction, setting up your
          account, verify your credit card, place an order, arrange for a delivery or return a
          purchase, we imply that you consent to our collecting it and using it for that specific
          reason only.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          If we ask for your personal information for a secondary reason, like marketing, we will
          either ask you directly for your expressed consent, or provide you with an opportunity to
          say no.
        </MKTypography>
        <MKTypography variant="h4" color="black" textAlign="left" mt={2} mx={2}>
          How do I withdraw my consent?
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          If after you opt-in, you change your mind, you may withdraw your consent for us to contact
          you, for the continued collection, use or disclosure of your information, at anytime, by
          contacting us at info@taotaro.app
        </MKTypography>

        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          Section 4- DISCLOSURE
        </MKTypography>

        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          We may disclose your personal information if we are required by law to do so or if you
          violate our Terms of Service.
        </MKTypography>

        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          Section 5- THIRD-PARTY SERVICES
        </MKTypography>

        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          In general, the third-party providers used by us will only collect, use and disclose your
          information to the extent necessary to allow them to perform the services they provide to
          us.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          However, certain third-party service providers, such as payment gateways and other payment
          transaction processors, have their own privacy policies in respect to the information we
          are required to provide to them for your purchase-related transactions.
        </MKTypography>

        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          For these providers, we recommend that you read their privacy policies so you can
          understand the manner in which your personal information will be handled by these
          providers.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          In particular, remember that certain providers may be located in or have facilities that
          are located in a different jurisdiction than either you or us. So if you elect to proceed
          with a transaction that involves the services of a third-party service provider, then your
          information may become subject to the laws of the jurisdiction(s) in which that service
          provider or its facilities are located.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          As an example, if you are located in Canada and your transaction is processed by a payment
          gateway located in the United States, then your personal information used in completing
          that transaction may be subject to disclosure under United States legislation, including
          the Patriot Act.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          Once you leave our website or are redirected to a third-party website or application, you
          are no longer governed by this Privacy Policy or our website’s Terms of Service.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          When you click on links on our store, they may direct you away from our site. We are not
          responsible for the privacy practices of other sites and encourage you to read their
          privacy statements.
        </MKTypography>

        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          Section 6- SECURITY
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          To protect your personal information, we take reasonable precautions and follow industry
          best practices to make sure it is not inappropriately lost, misused, accessed, disclosed,
          altered or destroyed.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          If you provide us with your credit card information, the information is encrypted using
          secure socket layer technology (SSL) and stored with a AES-256 encryption. Although no
          method of transmission over the Internet or electronic storage is 100% secure, we follow
          all PCI-DSS requirements and implement additional generally accepted industry standards.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          The website uses Google Analytics, which uses Cookies so please choose if you want to
          opt-out of cookies or not from your browser
        </MKTypography>

        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          Section 7- AGE OF CONSENT
        </MKTypography>

        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          By using this site, you represent that you are at least the age of majority in your state
          or province of residence, or that you are the age of majority in your state or province of
          residence and you have given us your consent to allow any of your minor dependents to use
          this site.
        </MKTypography>

        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          Section 8- CHANGES TO THIS PRIVACY POLICY
        </MKTypography>

        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          We reserve the right to modify this privacy policy at any time, so please review it
          frequently. Changes and clarifications will take effect immediately upon their posting on
          the website. If we make material changes to this policy, we will notify you here that it
          has been updated, so that you are aware of what information we collect, how we use it, and
          under what circumstances, if any, we use and/or disclose it.
        </MKTypography>
        <MKTypography variant="subtitle2" color="black" mx={4} mt={1}>
          If our store is acquired or merged with another company, your information may be
          transferred to the new owners so that we may continue to sell products to you.
        </MKTypography>

        <MKTypography variant="h5" color="black" textAlign="left" mt={5}>
          QUESTIONS AND CONTACT INFORMATION
        </MKTypography>

        <MKTypography variant="subtitle2" color="black" mx={4} mt={1} mb={10}>
          If you would like to: access, correct, amend or delete any personal information we have
          about you, register a complaint, or simply want more information contact our Privacy
          Compliance Officer at info@taotaro.app
        </MKTypography>
      </Card>
    </>
  );
}

export default PrivacyTerms;
