import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languages = ["en", "cn"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    language: "en",
    fallbackLng: ["en", "cn"],

    // debug: true,
    load: "currentOnly",
    lookupFromPathIndex: 0,

    whitelist: languages,
    // We are good here

    detect: {
      order: ["path"],
    },

    // lookupCookie: "next-i18next",
    // detection: {
    //   order: ["path", "navigator"],
    //   lookupFromPathIndex: 0,
    //   checkWhitelist: true,
    //   cache: ["cookie"],
    //   // excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    // },
    // interpolation: {
    //   escapeValue: false, // react already safes from xss
    //   formatSeparator: ".",
    // },
    react: {
      wait: true,
      useSuspense: false,
    },
  });
export default i18n;
