import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CTABlockTwo from "components/CTABlockTwo/CTABlockTwo";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import TestimonialsOne from "components/TestimonialsOne/TestimonialsOne";
import PartnersBlock from "components/PartnersBlock/PartnersBlock";
import {
  CLOUD_HEADER_IMAGE,
  CLOUD_ICON,
  ECONOMIST_ICON,
  EXPERTISE_ICON,
  MIGRATION_ICON,
  OPTIMIZE_ICON,
  PHONE_ICON,
  SECURITY_ICON,
  DIGITAL1_LOGO,
  ALIBABA_LOGO,
  GIRL_GAMER_LOGO,
  SECONDLIFE_LOGO,
  PROVALIDATOR_LOGO,
  MERRYBIZ_LOGO,
} from "commons/utils/constants";
import HeaderOne from "components/HeaderOne/HeaderOne";
import HeaderTwo from "components/HeaderTwo/HeaderTwo";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import ContentGridFour from "components/ContentGridFour/ContentGridFour";
import i18n from "../../i18n";

function CloudServices() {
  const { t } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;
  const customers = [
    {
      src: DIGITAL1_LOGO,
      alt: "digital-one",
      key: 0,
    },
    {
      src: SECONDLIFE_LOGO,
      alt: "2ndlife",
      key: 1,
    },
    {
      src: ALIBABA_LOGO,
      alt: "alibaba-cloud",
      key: 2,
    },
    {
      src: PROVALIDATOR_LOGO,
      alt: "provalidator",
      key: 3,
    },
    {
      src: MERRYBIZ_LOGO,
      alt: "merrybiz",
      key: 4,
    },
    {
      src: GIRL_GAMER_LOGO,
      alt: "girlgamer",
      key: 5,
    },
  ];
  const gridItems = [
    {
      icon: PHONE_ICON,
      title: i18n.t("Cloud.Information.item1.title"),
      description: i18n.t("Cloud.Information.item1.description"),
      key: 0,
    },
    {
      icon: MIGRATION_ICON,
      title: i18n.t("Cloud.Information.item2.title"),
      description: i18n.t("Cloud.Information.item2.description"),
      key: 1,
    },
    {
      icon: SECURITY_ICON,
      title: i18n.t("Cloud.Information.item3.title"),
      description: i18n.t("Cloud.Information.item3.description"),
      key: 2,
    },
    {
      icon: OPTIMIZE_ICON,
      title: i18n.t("Cloud.Information.item4.title"),
      description: i18n.t("Cloud.Information.item4.description"),
      key: 3,
    },
  ];
  const testimonals = [
    {
      name: i18n.t("Cloud.Testimonials.testimonial1.name"),
      review: i18n.t("Cloud.Testimonials.testimonial1.review"),
      color: "transparent",
      key: 0,
    },
    {
      name: i18n.t("Cloud.Testimonials.testimonial2.name"),
      review: i18n.t("Cloud.Testimonials.testimonial2.review"),
      color: "info",
      key: 1,
    },
    {
      name: i18n.t("Cloud.Testimonials.testimonial3.name"),
      review: i18n.t("Cloud.Testimonials.testimonial3.review"),
      color: "transparent",
      key: 2,
    },
  ];
  const about = [
    {
      icon: CLOUD_ICON,
      title: i18n.t("Cloud.About.Block1.title"),
      description: i18n.t("Cloud.About.Block1.description"),
      key: 0,
    },
    {
      icon: ECONOMIST_ICON,
      title: i18n.t("Cloud.About.Block2.title"),
      description: i18n.t("Cloud.About.Block2.description"),
      key: 1,
    },
    {
      icon: EXPERTISE_ICON,
      title: i18n.t("Cloud.About.Block3.title"),
      description: i18n.t("Cloud.About.Block3.description"),
      key: 2,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Helmet.Cloud")}</title>
        <meta name="description" content={t("Helmet.content.CloudServices")} />
      </Helmet>
      <Grid>
        <HeaderOne
          title={t("Cloud.mainBanner.title")}
          desc={t("Cloud.mainBanner.description")}
          buttonText={t("Cloud.mainBanner.button")}
          bannerImage={CLOUD_HEADER_IMAGE}
          buttonLink={`${baseRouteUrl}/contact`}
        />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid mt={5}>
          <ContentGridFour
            title={t("Cloud.About.title")}
            desc={t("Cloud.About.description")}
            content={about}
            button={t("Cloud.About.button")}
            type="image"
            buttonLink={`${baseRouteUrl}/contact`}
          />
        </Grid>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto" }}
          mt={5}
        >
          <HeaderTwo
            title={t("Cloud.Services.title")}
            desc={t("Cloud.Services.description")}
            align="center"
          />

          <ContentGridOne
            content={gridItems}
            color="info"
            itemsFour
            direction="center"
            type="image"
          />
        </Grid>
        <Grid mt={5}>
          <TestimonialsOne
            title={t("Cloud.Testimonials.title")}
            desc={t("Cloud.Testimonials.description")}
            content={testimonals}
          />
        </Grid>
        <Grid mt={8}>
          <PartnersBlock
            title={t("Homepage.Customers.title")}
            content={customers}
            width="80%"
            opacity={0.8}
          />
        </Grid>
        <Grid mt={8} mb={2}>
          <CTABlockTwo
            title={t("Cloud.Footer.title")}
            desc={t("Cloud.Footer.description")}
            button={t("Cloud.Footer.button")}
            color="warning"
          />
        </Grid>
      </Card>
    </>
  );
}

export default CloudServices;
