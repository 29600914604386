// @mui material components
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import ListItem from "components/ListItem";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function ContentBlockTwo({ title, content, contentImage }) {
  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        sx={{ mx: { lg: "auto", xl: "auto", sm: 0, md: 0 } }}
        px={2}
      >
        <Grid
          sx={{ mr: { lg: 5, sm: 0 }, mx: "auto" }}
          lg={6}
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          mt={6}
          data-aos="fade-right"
        >
          <ListItem title={title}>
            {content.map((object) => (
              <Grid key={object.key}>
                <MKTypography component="div" variant="h6" color="text" mt={5}>
                  {object.subheading}
                </MKTypography>
                <MKTypography variant="body2" component="span" color="text" mb={4}>
                  {object.para}
                </MKTypography>
              </Grid>
            ))}
          </ListItem>
        </Grid>
        <Grid item sx={{ mr: "auto" }} data-aos="fade-up">
          <Card
            sx={{
              backgroundImage: `url(${contentImage})`,
              height: "50vh",
              width: "35vh",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: {
                sm: "none",
                xs: "none",
                md: "none",
                lg: "block",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

ContentBlockTwo.propTypes = {
  title: PropTypes.string.isRequired,
  contentImage: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]))
  ).isRequired,
};

export default ContentBlockTwo;
