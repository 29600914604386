// import Container from "@mui/material/Container";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
// import ListItem from "pages/Support/HelpCenter/components/ListItem";

import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function Features({ title, content, color }) {
  return (
    <>
      <Grid
        container
        item
        flexDirection="column"
        xs={12}
        lg={10}
        sx={{ textAlign: "center", mx: "auto" }}
        data-aos="fade-up"
      >
        <MKTypography variant="h3" mb={1}>
          {title}
        </MKTypography>
        {/* <MKTypography variant="body2">{desc}</MKTypography> */}
      </Grid>
      <Grid container item xs={12} lg={10} mt={2} mx="auto">
        {content.map(({ icon, name }) => (
          <Grid key={name} item xs={12} md={4} data-aos="fade-up" data-aos-delay="200">
            <MKBox p={2} textAlign="center" borderRadius="lg">
              <MKTypography variant="h3" color={color} mb={2} textGradient>
                <MKBox
                  sx={{
                    backgroundImage: `url(${icon})`,
                    height: { lg: "5vh", md: "3vh", xs: "5vh" },
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: { lg: "5vh", md: "3vh", xs: "5vh" },
                    ml: 0,
                  }}
                />
              </MKTypography>
              <MKTypography variant="h6">{name}</MKTypography>
            </MKBox>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

Features.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  color: PropTypes.string.isRequired,
};

export default Features;
