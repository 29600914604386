/* eslint-env browser */

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import AOS from "aos";
import { PRODUCTION } from "./commons/utils/constants";

import Layout from "./commons/HOC/Layout/Layout";

if (process.env.REACT_APP_STAGE === PRODUCTION) {
  ReactGA.initialize("UA-181511439-2", {
    debug: false,
  });
}

export default function App() {
  AOS.init({
    once: true,
    disable: "phone",
    duration: 600,
    easing: "ease-out-sine",
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout />
    </ThemeProvider>
  );
}
