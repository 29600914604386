import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import ContentBlockOne from "components/ContentBlockOne/ContentBlockOne";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import ContentGridFour from "components/ContentGridFour/ContentGridFour";
import HeaderOne from "components/HeaderOne/HeaderOne";

import { Helmet } from "react-helmet";
import CTABlock from "components/CTABlock/CTABlock";
import {
  PIM_FOOTER_IMAGE,
  DRINKAGAIN_HEADER_IMAGE,
  IDEA_ICON,
  DEVELOPMENT2_ICON,
  LEARNING_ICON,
  EASE_OF_USE_ICON,
  // POPUP_STORE_ICON,
  SAKE_ICON,
  EXPERTISE_ICON,
} from "commons/utils/constants";

import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function DrinkAgain() {
  const { t } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;
  const gridItems = [
    {
      icon: IDEA_ICON,
      title: i18n.t("drinkAgain.intro.item1.title"),
      description: i18n.t("drinkAgain.intro.item1.description"),
      key: 0,
    },
    {
      icon: DEVELOPMENT2_ICON,
      title: i18n.t("drinkAgain.intro.item2.title"),
      description: i18n.t("drinkAgain.intro.item2.description"),
      key: 1,
    },
    {
      icon: LEARNING_ICON,
      title: i18n.t("drinkAgain.intro.item3.title"),
      description: i18n.t("drinkAgain.intro.item3.description"),
      key: 2,
    },
  ];

  const builtFeature = [
    {
      icon: EASE_OF_USE_ICON,
      title: i18n.t("drinkAgain.why.Block1.title"),
      description: i18n.t("drinkAgain.why.Block1.description"),
      key: 0,
    },
    {
      icon: SAKE_ICON,
      title: i18n.t("drinkAgain.why.Block2.title"),
      description: i18n.t("drinkAgain.why.Block2.description"),
      key: 1,
    },
    {
      icon: EXPERTISE_ICON,
      title: i18n.t("drinkAgain.why.Block3.title"),
      description: i18n.t("drinkAgain.why.Block3.description"),
      key: 2,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Helmet.DrinkAgain")}</title>
        <meta name="description" content={t("Helmet.content.DrinkAgain")} />
      </Helmet>
      <Grid>
        <HeaderOne
          title={t("drinkAgain.mainBanner.title")}
          desc={t("drinkAgain.mainBanner.description")}
          buttonText={t("PIM.mainBanner.button")}
          bannerImage={DRINKAGAIN_HEADER_IMAGE}
          buttonLink={`${baseRouteUrl}/contact`}
        />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          mt={5}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <ContentGridOne
            title={t("drinkAgain.intro.title")}
            desc={t("drinkAgain.intro.description")}
            content={gridItems}
            color="info"
            direction="center"
            type="image"
          />
        </Grid>
        <Grid mt={5}>
          <ContentGridFour
            title={t("drinkAgain.why.title")}
            desc={t("drinkAgain.why.description")}
            content={builtFeature}
            button={t("drinkAgain.why.button")}
            type="image"
            buttonLink={`${baseRouteUrl}/contact`}
          />
        </Grid>
        <Grid mt={8}>
          <ContentBlockOne
            title={t("drinkAgain.Block1.title")}
            desc={t("drinkAgain.Block1.description")}
            contentImage="https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/drinkagain/sake_demo.png"
            alignRight
          />
          {/* <Divider sx={{ my: { xs: 2, sm: 4 }, mx: 12 }} />
          <ContentBlockOne
            title={t("drinkAgain.why.Block2.title")}
            desc={t("drinkAgain.why.Block2.description")}
            contentImage={CART_FEATURE_2}
          />
          <ContentBlockOne
            title={t("drinkAgain.why.Block3.title")}
            desc={t("drinkAgain.why.Block3.escription")}
            contentImage={CART_FEATURE_3}
            alignRight
          /> */}
        </Grid>

        <Grid mt={8}>
          <CTABlock
            bgImage={PIM_FOOTER_IMAGE}
            title={t("ContactUsBanner.title")}
            button={t("ContactUsBanner.button")}
            titleColor="white"
            buttonColor="warning"
          />
        </Grid>
      </Card>
    </>
  );
}
export default DrinkAgain;
