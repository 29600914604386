import Grid from "@mui/material/Grid";
// import MKButton from "components/MKButton";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
import AboutUsOption from "pages/CloudServices/sections/AboutUsOption";
import PropTypes from "prop-types";
// import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";
import { Icon } from "@mui/material";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "../../i18n";

AOS.init();
const baseRouteUrl = `/${i18n.language}`;

function ContentGridFour({ title, desc, button, content, type, buttonLink, buttonDisable }) {
  return (
    <>
      <Grid container item xs={12} lg={10} mx="auto" sx={{ px: { md: 2, xs: 0 } }}>
        <Grid item xs={12} lg={5} data-aos="fade-up">
          <Grid sx={{ textAlign: { xs: "center", md: "left" } }}>
            <MKTypography variant="h3" mb={1}>
              {title}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              {desc}
            </MKTypography>
          </Grid>
          {buttonDisable ? (
            <></>
          ) : (
            <Grid sx={{ display: { xs: "none", md: "block" } }}>
              <MKTypography
                component={Link}
                to={buttonLink}
                variant="button"
                color="info"
                fontWeight="regular"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: "translateX(3px)",
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: "translateX(6px)",
                  },
                }}
              >
                {button}
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} lg={6} sx={{ ml: { xs: 0, lg: "auto" } }}>
          {content.map((object) => (
            <Grid data-aos="fade-left" key={object.key}>
              <AboutUsOption
                icon={object.icon}
                type={type}
                content={
                  <>
                    {object.title}
                    <br />
                    {object.description}
                  </>
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
ContentGridFour.defaultProps = {
  buttonDisable: false,
  buttonLink: `${baseRouteUrl}/contact`,
  button: "",
};

ContentGridFour.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  button: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]))
  ).isRequired,
  type: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  buttonDisable: PropTypes.bool,
};

export default ContentGridFour;
