import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import ContentBlockOne from "components/ContentBlockOne/ContentBlockOne";
import ContentGridOne from "components/ContentGridOne/ContentGridOne";
import ContentGridFour from "components/ContentGridFour/ContentGridFour";
import HeaderOne from "components/HeaderOne/HeaderOne";

import { Helmet } from "react-helmet";
import CTABlock from "components/CTABlock/CTABlock";
import {
  PIM_FOOTER_IMAGE,
  CART_HEADER_IMAGE,
  SETUP_CART_ICON,
  SET_PRODUCTS_ICON,
  CONFIG_PAY_ICON,
  START_SELLING,
  EASE_OF_USE_ICON,
  POPUP_STORE_ICON,
  EXPERTISE_ICON,
  CART_FEATURE_1,
  CART_FEATURE_2,
  CART_FEATURE_3,
} from "commons/utils/constants";

import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function RapidCart() {
  const { t } = useTranslation();
  const baseRouteUrl = `/${i18n.language}`;
  const gridItems = [
    {
      icon: SETUP_CART_ICON,
      title: i18n.t("Cart.getStarted.item1.title"),
      description: i18n.t("Cart.getStarted.item1.description"),
      key: 0,
    },
    {
      icon: SET_PRODUCTS_ICON,
      title: i18n.t("Cart.getStarted.item2.title"),
      description: i18n.t("Cart.getStarted.item2.description"),
      key: 1,
    },
    {
      icon: CONFIG_PAY_ICON,
      title: i18n.t("Cart.getStarted.item3.title"),
      description: i18n.t("Cart.getStarted.item3.description"),
      key: 2,
    },
    {
      icon: START_SELLING,
      title: i18n.t("Cart.getStarted.item4.title"),
      description: i18n.t("Cart.getStarted.item4.description"),
      key: 3,
    },
  ];

  const builtFeature = [
    {
      icon: EASE_OF_USE_ICON,
      title: i18n.t("Cart.builtFeature.Block1.title"),
      description: i18n.t("Cart.builtFeature.Block1.description"),
      key: 0,
    },
    {
      icon: POPUP_STORE_ICON,
      title: i18n.t("Cart.builtFeature.Block2.title"),
      description: i18n.t("Cart.builtFeature.Block2.description"),
      key: 1,
    },
    {
      icon: EXPERTISE_ICON,
      title: i18n.t("Cart.builtFeature.Block3.title"),
      description: i18n.t("Cart.builtFeature.Block3.description"),
      key: 2,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Helmet.RapidCart")}</title>
        <meta name="description" content={t("Helmet.content.RapidCart")} />
      </Helmet>
      <Grid>
        <HeaderOne
          title={t("Cart.mainBanner.title")}
          desc={t("Cart.mainBanner.description")}
          buttonText={t("PIM.mainBanner.button")}
          bannerImage={CART_HEADER_IMAGE}
          buttonLink={`${baseRouteUrl}/contact`}
          isButton
        />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid mt={5}>
          <ContentGridOne
            title={t("Cart.getStarted.title")}
            desc={t("Cart.getStarted.desc")}
            content={gridItems}
            color="info"
            itemsFour
            direction="center"
            type="image"
          />
        </Grid>
        <Grid mt={5}>
          <ContentGridFour
            title={t("Cart.builtFeature.title")}
            desc={t("Cart.builtFeature.description")}
            content={builtFeature}
            button={t("Cart.builtFeature.button")}
            type="image"
            buttonLink={`${baseRouteUrl}/contact`}
          />
        </Grid>
        <Grid mt={8}>
          <ContentBlockOne
            title={t("Cart.feature1.title")}
            desc={t("Cart.feature1.description")}
            contentImage={CART_FEATURE_1}
            alignRight
          />
          <Divider sx={{ my: { xs: 2, sm: 4 }, mx: 12 }} />
          <Grid>
            <ContentBlockOne
              title={t("Cart.feature2.title")}
              desc={t("Cart.feature2.description")}
              contentImage={CART_FEATURE_2}
            />
          </Grid>
          <Grid sx={{ mt: { md: 2, xs: 5 } }}>
            <ContentBlockOne
              title={t("Cart.feature3.title")}
              desc={t("Cart.feature3.description")}
              contentImage={CART_FEATURE_3}
              alignRight
            />
          </Grid>
        </Grid>

        <Grid mt={8}>
          <CTABlock
            bgImage={PIM_FOOTER_IMAGE}
            title={t("ContactUsBanner.title")}
            button={t("ContactUsBanner.button")}
            titleColor="white"
            buttonColor="warning"
          />
        </Grid>
      </Card>
    </>
  );
}
export default RapidCart;
