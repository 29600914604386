// navbar
export const BRAND_LOGO_BLACK =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/general/taotaro-logo-wb-2%401x-black.png";

export const BRAND_LOGO_WHITE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/general/taotaro-logo-wb-2%401x-white.png";

// logos
export const ALIBABA_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-alibaba.png";
export const CHILLAX_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-chillax.png";
export const EEVY_LOGO = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-eevy.png";
export const HKSTP_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-hkstp.png";
export const LOTUS_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-lotus.png";
export const WECHAT_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-wechat.png";
export const CPJOBS_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-cpjobs.png";
export const JEBSEN_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-jebsen.png";
export const SWIRE_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-swire-properties.png";
export const YUEHWA_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-yuehwa.png";
export const ZTORE_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-ztore.png";
export const PET_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-pet-project.png";
export const YOGURTRAIN_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-yogurtrain.png";
export const DIGITAL1_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-d1.png";
export const HKSTP_INCUTECH_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/logo-incutech-hkstp";
export const MERRYBIZ_LOGO =
  "http://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/merrybiz_logo.svg";
export const PROVALIDATOR_LOGO =
  "http://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/provalidator-logo.svg";
export const SECONDLIFE_LOGO =
  "http://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/Second_Life_Logo-blk.png";

// homepage
export const HOMEPAGE_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/banner-home.jpg";
export const HOMEPAGE_VALUES_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/sample/test-presentation1.jpg";
export const HOMEPAGE_FOOTER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/sample/test-presentation1.jpg";
export const PIM_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/pim.svg";
export const DEVELOPMENT_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/development.svg";
export const ECOMMERCE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/ecommerce2.svg";
export const CLOUD2_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/home/cloud2.svg";

// PIM
export const PIM_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/PIM_header.png";
export const PIM_INTRO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/pim-intro%20%281%29.png";
export const PIM_WHAT = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/What_PIM";
export const PIM_WHY = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/Why_PIM";
export const PIM_MANAGE = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/Manage_PIM";
export const PIM_FOOTER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/PIM_footer_image.png";
export const COLLECT_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/collect-icon.svg";
export const DISTRIBUTE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/distribute-icon.svg";
export const ENRICH_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/enrich-icon.svg";
export const MANAGE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/manage-icon.svg";
export const CREDIT_CARD_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/Credit-card.png";
export const DEVELOPER_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/Developer-mode.png";
export const LIFEBUOY_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/lifebuoy.png";
export const NONSTOP_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/nonstop.png";
export const WRITING_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/writing.png";
export const PIM_MAIN_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/pim/PIM_image.png";

// Cart
export const CART_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/banner-cart.jpg";
export const SETUP_CART_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/webpage.svg";
export const SET_PRODUCTS_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/product.svg";
export const CONFIG_PAY_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/mobile-payment.svg";
export const START_SELLING = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/trade.svg";
export const EASE_OF_USE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/continuous.svg";
export const POPUP_STORE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/online-store.svg";
export const CART_FEATURE_1 =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/feature1.jpg";
export const CART_FEATURE_2 =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/feature2.jpg";
export const CART_FEATURE_3 =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cart/feature3.jpg";

// drinkagain
export const DRINKAGAIN_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/drinkagain/drinkagain-banner.jpg";

export const IDEA_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/drinkagain/light-bulb.svg";
export const DEVELOPMENT2_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/drinkagain/coding.svg";
export const LEARNING_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/drinkagain/machine-learning.svg";
export const SAKE_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/drinkagain/sake.svg";

// eCommerce
export const ECOMMERCE_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/banner-ecommerce.jpg";
export const ECOMMERCE_FOOTER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/sample/test-presentation1.jpg";
export const ECOMMERCE_DESC_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/ecommerce1.jpg";
export const API_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/API-icon.svg";
export const DESIGN_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/design-icon.svg";
export const GROWTH_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/growth-icon.svg";
export const PLATFORM_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/platform-icon.svg";
export const STRATEGY_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/strategy-icon.svg";
export const SUPPORT_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/ecommerce/support-icon.svg";

// development
export const DEVELOPMENT_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/development/banner-development.jpg";
export const DEVELOPMENT_PROCESS_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/sample/test-presentation1.jpg";

// cloud
export const CLOUD_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/banner-cloud.jpg";
export const CLOUD_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/cloud-icon.svg";
export const ECONOMIST_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/economist-icon.svg";
export const EXPERTISE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/expertise-icon.svg";
export const MIGRATION_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/migration-icon.svg";
export const OPTIMIZE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/optimize-icon.svg";
export const PHONE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/phone-icon.svg";
export const SECURITY_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/cloud/security-icon.svg";

// team
export const TEAM_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/banner-team.jpg";
export const CHALLENGES_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/challenges.svg";
export const EVOLUTION_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/evolution.svg";
export const CONTENT1_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/feature-galaxy.jpg";
export const GIFT_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/gift.svg";
export const LAUGH_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/laugh.svg";

export const TEAM = {
  nelson: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/nelson.png",
  kelvin: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/kelvin.png",
  edmund: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/edmund.png",
  edward: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/edward.png",
  tamara: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/tamara.png",
  kavya: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/kavya.jpg",
  aurora: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/aurora.png",
  jacky: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/jacky.png",
  rafael: "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/team/rafael.jpg",
};

// contact
export const CONTACT_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/contact/banner-contact.jpg";

export const PRODUCTION = "production";

// streaming
export const GIRL_GAMER_LOGO =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/streaming/image%2070.png";

export const STREAMING_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/streaming/streamingImage.png";

export const TESTIMONIALS = {
  fernando:
    "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/streaming/Fernando%20X%20Pereira%201.png",
};

// weimob
export const LICENSING_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/weimob/licensing%20%282%29%201.png";
export const PREPARE_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/weimob/webpage.png";
export const SHIP_ICON =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/weimob/product%20%283%29%201.png";
export const SELL_ICON = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/weimob/trade.png";
export const CAPTURE_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/streaming/capture.png";
export const LIVE_IMAGE = "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/streaming/live.png";
export const TRANSCODE_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/streaming/transcode.png";

export const WEIMOB_HEADER_IMAGE =
  "https://web-homepage-v3.oss-cn-hongkong.aliyuncs.com/weimob/headerImage.png";
