/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components

import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
// import MKBox from "components/MKBox";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Terms() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("Helmet.Homepage")}</title>
        <meta name="description" content="test_content" />
      </Helmet>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mb: 4,
          backgroundColor: ({ palette: { black }, functions: { rgba } }) => rgba(black.main, 0.8),
          height: "15vh",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 1,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h5" color="dark" textAlign="left" mt={1}>
          I. Account Terms
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              You must be 18 years or older or at least the age of majority in the jurisdiction
              where you reside or from which you use this Service.
            </li>
            <li>
              To access and use the Services, you must register for a TaoTaro account (“Account”) by
              providing your full legal name, current address, phone number, a valid email address,
              and any other information indicated as required. TaoTaro reserves the right to reject
              your application for an Account, or cancel an existing Account, for any reason, in our
              sole discretion.
            </li>
            <li>
              You acknowledge that TaoTaro will use the email address you provide as the primary
              method for communication.
            </li>
            <li>
              You are responsible for keeping your password secure. TaoTaro cannot and will not be
              liable for any loss or damage from your failure to maintain the security of your
              Account and password.
            </li>
            <li>
              You are responsible for all activity and content such as data, graphics, photos and
              links that is uploaded under your TaoTaro Account (“Store Content”). You must not
              transmit any worms or viruses or any code of a destructive nature.
            </li>
            <li>
              A breach or violation of any term in the Terms of Service as determined in the sole
              discretion of TaoTaro will result in an immediate termination of your services.
            </li>
            <li>
              Don’t use TaoTaro for anything illegal or transmit any harmful code. Remember that
              with any violation of these terms we will cancel your service.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          II. Account Activitation
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              If you are an individual signing up for the Service will be the contracting party
              (“Account Owner”) for the purposes of our Terms of Service and will be the person who
              is authorized to use any corresponding account we may provide to the Account Owner in
              connection with the Service.{" "}
            </li>
            <li>
              If you are signing up for the Service on behalf of your employer, your employer shall
              be the Account Owner, you will represent and warrant that you have the authority to
              bind your employer to our Terms of Service.
            </li>
            <li>You acknowledge that WeChat payment will be your default payments gateway.</li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          III. Compliance with Chinese Law
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              When using the WeChat shop / miniapp, “Account Owner” will comply with legal
              stipulations and social public interest in China
            </li>
            <li>
              In particular, “Account Owner” will strictly abide by the following law and
              stipulation:{" "}
              <MKTypography variant="subtitle2" color="dark" mx={4} my={2}>
                <ol>
                  <ul>
                    a. International Computer Information Network Security Administration Approach
                  </ul>
                  <ul>
                    b. The People’s Republic of China Computer Information Network International
                    Network Administration Interim Regulations{" "}
                  </ul>
                  <ul>c. Regulations on Protection of the People’s Republic of China</ul>
                  <ul>d. Computer Information System Security Telecommunications Regulations</ul>
                  <ul>e. NPC Standing Committee on Safeguarding Internet Security Decision</ul>
                  <ul>f. Internet Information Services, Internet Electronic Bulletin Service</ul>
                  <ul>g. Website for business of News Publication Interim Regulations</ul>
                  <ul>h. Internet Audio-visual Program Administration Approach</ul>
                  <ul>i. Interim Administration of Internet Culture Regulation </ul>
                  <ul>j. other laws, stipulations, administrative regulations.</ul>
                </ol>
              </MKTypography>
            </li>
            <li>
              Any harmful information that laws forbid to produce, copy, promulgate and broadcast
              related to the use of the WeChat shop shall not be allowed.
            </li>
            <li>
              Party A will obtain all relevant certification and approval if it intends to apply
              services to business activities that need verification and approval by government
              bureaus. Party A shall undertake full responsibility for any disputes caused by Party
              A’s violation of this article;
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          IV. General Conditions
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              You must read, agree with and accept all of the terms and conditions contained in
              these Terms of Service and the Privacy Policy before when registering for an account
            </li>
            <li>
              The Terms of Service shall be governed by and interpreted in accordance with the laws
              of Hong Kong Special Administrative Region of the People’s Republic of China
              applicable therein, without regard to principles of conflicts of laws. The parties
              irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of
              the Hong Kong Special Administrative Region of the People’s Republic of China with
              respect to any dispute or claim arising out of or in connection with the Terms of
              Service.
            </li>
            <li>
              You acknowledge and agree that TaoTaro may amend these Terms of Service at any time by
              posting the relevant amended and restated Terms of Service on TaoTaro’s website, and
              such amendments to the Terms of Service are effective as of the date of posting.
              Please deactivate your account if you at any time disagree with TaoTaro’s most recent
              posted Terms of Use & Services. Your continued use of the “Account” after the amended
              Terms of Service constitutes your agreement to, and acceptance of, the amended Terms
              of Service.
            </li>
            <li>
              You may not use the TaoTaro’s service for any illegal or unauthorized purpose nor may
              you, in the use of the Service, violate any laws in your jurisdiction (including but
              not limited to copyright laws), the laws applicable to you in your customer’s
              jurisdiction, or the laws of Hong Kong Special Administrative Region of the People’s
              Republic of China and the People’s Republic of China. You will comply with all
              applicable laws, rules and regulations in your use of the Service.
            </li>
            <li>
              You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of
              the Service, use of the Service, or access to the Service without the express written
              permission by TaoTaro
            </li>
            <li>Questions about the Terms of Service should be sent to info@taoTaro.app</li>
            <li>
              You understand that your Store Content (not including credit card information), may be
              transferred unencrypted and involve (a) transmissions over various networks; and (b)
              changes to conform and adapt to technical requirements of connecting networks or
              devices. Credit Card information is always encrypted during transfer over networks.
            </li>
            <li>
              You acknowledge and agree that your use of the Service, including information
              transmitted to or stored by TaoTaro, is governed by its privacy policy
            </li>
            <li>
              The parties have required that the Terms of Service and all documents relating thereto
              be drawn up in English.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          V. TaoTaro’s Rights
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              We reserve the right to modify or terminate the Service for any reason, without notice
              at any time.
            </li>
            <li>We reserve the right to refuse service to anyone for any reason at any time.</li>
            <li>
              We may, but have no obligation to, remove Store Content and Accounts containing
              content that we determine in our sole discretion are unlawful, offensive, threatening,
              libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any
              party’s intellectual property or these Terms of Service.
            </li>
            <li>
              Verbal or written abuse of any kind (including threats of abuse or retribution) of any
              TaoTaro’s stakeholders will result in immediate Account termination.
            </li>
            <li>
              TaoTaro does not pre-screen Store Content and it is in our sole discretion to refuse
              or remove any Store Content that is available via the Service.
            </li>
            <li>
              We reserve the right to provide our services to your competitors and make no promise
              of exclusivity in any particular market segment. You further acknowledge and agree
              that TaoTaro’s employees and contractors may also be Taotaro’s customers/merchants and
              that they may compete with you, although they may not use your confidential
              information in doing so.
            </li>
            <li>
              In the event of a dispute regarding Account ownership, we reserve the right to request
              documentation to determine or confirm Account ownership. Documentation may include,
              but is not limited to, a scanned copy of your business license, government issued
              photo ID, the last four digits of the credit card on file, etc.
            </li>
            <li>
              TaoTaro retains the right to determine, in our sole judgment, rightful Account
              ownership and transfer an Account to the rightful owner. If we are unable to
              reasonably determine the rightful Account owner, TaoTaro reserves the right to
              temporarily disable an Account until resolution has been determined between the
              disputing parties.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          VI. Limitation of Liability
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              You expressly understand and agree that TaoTaro shall not be liable for any direct,
              indirect, incidental, special, consequential or exemplary damages, including but not
              limited to, damages for loss of profits, goodwill, use, data or other intangible
              losses resulting from the use of or inability to use the service.
            </li>
            <li>
              In no event shall TaoTaro or our suppliers be liable for lost profits or any special,
              incidental or consequential damages arising out of or in connection with our site, our
              services or these Terms of Service (however arising including negligence). You agree
              to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates,
              TaoTaro’s partners, officers, directors, agents, employees, and suppliers harmless
              from any claim or demand, including reasonable attorneys’ fees, made by any third
              party due to or arising out of your breach of these Terms of Service or the documents
              it incorporates by reference, or your violation of any law or the rights of a third
              party.
            </li>
            <li>
              Your use of the Service is at your sole risk. The Service is provided on an “as is”
              and “as available” basis without any warranty or condition, express, implied or
              statutory.
            </li>
            <li>
              TaoTaro does not guarantee that the Service will be uninterrupted, timely, secure, or
              error-free.
            </li>
            <li>
              TaoTaro does not guarantee that the results that may be obtained from the use of the
              Service will be accurate or reliable.
            </li>
            <li>
              TaoTaro does not guarantee that the quality of any products, services, information, or
              other material purchased or obtained by you through the Service will meet your
              expectations, or that any errors in the Service will be corrected.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          VII. Waiver and Complete Agreement
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              The failure of TaoTaro to exercise or enforce any right or provision of the Terms of
              Service shall not constitute a waiver of such right or provision. The Terms of Service
              constitutes the entire agreement between you and TaoTaro and govern your use of the
              Service, superseding any prior agreements between you and TaoTaro (including, but not
              limited to, any prior versions of the Terms of Service).
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          VIII. Intellectual Property and Customer Content
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              We do not claim any intellectual property rights over the material you provide to the
              TaoTaro service. All material you upload remains yours. You can remove your TaoTaro
              store at any time by deleting your Account.
            </li>
            <li>
              By uploading Store Content, you agree: (a) to allow other internet users to view your
              Store Content; (b) to allow TaoTaro to display and store your Store Content; and (c)
              that TaoTaro can, at any time, review all the Store Content submitted by you to its
              Service.
            </li>
            <li>
              You retain ownership over all Store Content that you upload to a TaoTaro store;
              however, by making your store public, you agree to allow others to view your Store
              Content. You are responsible for compliance of Store Content with any applicable laws
              or regulations.
            </li>
            <li>
              We will not disclose your confidential information to third parties, except as
              required in the course of providing our services. Confidential information includes
              any materials or information provided by you to us which is not publicly known.
              Confidential information does not include information that: (a) was in the public
              domain at the time we received it; (b) comes into the public domain after we received
              it through no fault of ours; (c) we received from someone other than you without
              breach of our or their confidentiality obligations; or (d) we are required by law to
              disclose.
            </li>
            <li>
              TaoTaro shall have the non-exclusive right and license to use the names, trademarks,
              service marks and logos associated with your store to promote the Service.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          IX. Payment of Fees
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              You will pay the Fees applicable to your subscription to Online Service and/or POS
              Services (“Subscription Fees”) and any other applicable fees, including but not
              limited to fees relating to the processing of transactions under your Account
              (“Transaction Fees”), and any fees relating to your purchase of any products or
              services such as POS Equipment, shipping, apps, Themes, domain names or Third Party
              Services (“Additional Fees”). Together, the Subscription Fees, Transaction Fees and
              the Additional Fees are referred to as the “Fees”.
            </li>
            <li>
              You must keep a valid credit card on file with us to pay for all incurred and
              recurring Fees. TaoTaro will charge applicable Fees to the credit card account that
              you authorize (“Authorized Card”), and TaoTaro will continue to charge the Authorized
              Card (or any replacement card) for applicable Fees until the Services are terminated,
              and any and all outstanding Fees have been paid in full. Unless otherwise indicated,
              all Fees and other charges are in U.S. dollars, and all payments shall be in U.S.
              currency.
            </li>
            <li>
              Subscription Fees are paid in advance and will be billed in 30 day intervals (each
              such date, a “Billing Date”). Transaction Fees and Additional Fees will be charged
              from time to time at TaoTaro’s discretion. You will be charged on each Billing Date
              for all outstanding Fees that have not previously been charged. Fees will appear on an
              invoice, which will be sent to the Account Owner via the email provided. As well, an
              invoice will appear on the Account page of your TaoTaro administration console. Users
              have approximately two weeks to bring up and settle any issues with the billing of
              Subscription Fees.
            </li>
            <li>
              All Fees are exclusive of applicable federal, provincial, state, local or other
              governmental sales, goods and services, harmonized or other taxes, fees or charges now
              in force or enacted in the future (“Taxes”).
            </li>
            <li>
              Taxes may apply to your subscription to or purchase of some or all of TaoTaro’s
              products and services, including without limitation, your subscription to or purchase
              of TaoTaro’s Online Services. Any applicable Taxes are based on the rates applicable
              to the billing address you provide to us, and will be calculated at the time of
              purchase of the applicable Taxable Offerings. Such amounts are in addition to the Fees
              for the Taxable Offerings and will be billed to your Authorized Card. If you are
              exempt from payment of such Taxes, you must provide us with an original certificate
              that satisfies applicable legal requirements attesting to tax-exempt status. Tax
              exemption will only apply from and after the date we receive such a certificate.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          X. Cancellation and Termination
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              You may cancel your Account at any time by emailing info@taotaro.app and then
              following the specific instructions indicated to you in TaoTaro’s response.
            </li>
            <li>
              Upon termination of the Services by either party for any reason:
              <MKTypography variant="subtitle2" color="dark" mx={4} my={2}>
                <ol>
                  <ul>
                    a. TaoTaro will cease providing you with the Services and you will no longer be
                    able to access your Account;
                  </ul>
                  <ul>
                    b. unless otherwise provided in the Terms of Service, you will not be entitled
                    to any refunds of any Fees, pro rata or otherwise;
                  </ul>
                  <ul>
                    c. any outstanding balance owed to TaoTaro for your use of the Services through
                    the effective date of such termination will immediately become due and payable
                    in full; and
                  </ul>
                  <ul>
                    d. Our account manager will reach out to you about other requirements if any
                    before final termination
                  </ul>
                </ol>
              </MKTypography>
            </li>

            <li>
              If you purchased a domain name through TaoTaro, upon cancellation your domain will no
              longer be automatically renewed. Following cancellation, it will be your sole
              responsibility to handle all matters related to your domain with the domain provider.
            </li>
            <li>
              If at the date of termination of the Service, there are any outstanding Fees owing by
              you, you will receive one final invoice via email. Once that invoice has been paid in
              full, you will not be charged again.
            </li>
            <li>
              We reserve the right to modify or terminate the TaoTaro Service or your Account for
              any reason, without notice at any time.
            </li>
            <li>
              Fraud: Without limiting any other remedies, TaoTaro may suspend or terminate your
              Account if we suspect that you (by conviction, settlement, insurance or escrow
              investigation, or otherwise) have engaged in fraudulent activity in connection with
              the Site.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          XI. Modification to the Service and Prices
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1}>
          <ol>
            <li>
              Prices for using the Services are subject to change upon 30 days notice from TaoTaro.
              Such notice may be provided at any time by posting the changes to the TaoTaro Site
              (TaoTaro.com) or the administration menu of your TaoTaro store via an announcement.
            </li>
            <li>
              TaoTaro reserves the right at any time, and from time to time, to modify or
              discontinue, the Service (or any part thereof) with or without notice.
            </li>
            <li>
              TaoTaro shall not be liable to you or to any third party for any modification, price
              change, suspension or discontinuance of the Service.
            </li>
          </ol>
        </MKTypography>

        <MKTypography variant="h5" color="dark" textAlign="left" mt={5}>
          XII. Third Party Services
        </MKTypography>
        <MKTypography variant="subtitle2" color="dark" mx={4} mt={1} mb={10}>
          <ol>
            <li>
              In addition to these Terms of Service, you also agree to be bound by the additional
              service-specific terms applicable to services you purchase from, or that are provided
              by, TaoTaro’s partners or other third parties.
            </li>
            <li>
              TaoTaro may from time to time recommend, provide you with access to, or enable third
              party software, applications (“Apps”), products, services or website links
              (collectively, “Third Party Services”) for your consideration or use, including via
              the TaoTaro App Store. Such Third Party Services are made available only as a
              convenience, and your purchase, access or use of any such Third Party Services is
              solely between you and the applicable third party services provider (“Third Party
              Provider”). Any use by you of Third Party Services offered through the Services or
              TaoTaro’s website is entirely at your own risk and discretion, and it is your
              responsibility to read the terms and conditions and/or privacy policies applicable to
              such Third Party Services before using them.
            </li>
            <li>
              We do not provide any warranties with respect to Third Party Services. You acknowledge
              that TaoTaro has no control over Third Party Services, and shall not be responsible or
              liable to anyone for such Third Party Services. The availability of Third Party
              Services on TaoTaro’s websites, including the TaoTaro App Store, or the integration or
              enabling of such Third Party Services with the Services does not constitute or imply
              an endorsement, authorization, sponsorship, or affiliation by or with TaoTaro. TaoTaro
              strongly recommends that you seek specialist advice before using or relying on Third
              Party Services, to ensure they will meet your needs. In particular, tax calculators
              should be used for reference only and not as a substitute for independent tax advice
              when assessing the correct tax rates you should charge to your customers.
            </li>
            <li>
              If you install or enable a Third Party Service for use with the Services, you grant us
              permission to allow the applicable Third Party Provider to access your data and to
              take any other actions as required for the interoperation of the Third Party Service
              with the Services, and any exchange of data or other interaction between you and the
              Third Party Provider is solely between you and such Third Party Provider. TaoTaro is
              not responsible for any disclosure, modification or deletion of your data or Store
              Content, or for any corresponding losses or damages you may suffer, as a result of
              access by a Third Party Service or a Third Party Provider to your data or Store
              Content.
            </li>
            <li>
              Under no circumstances shall TaoTaro be liable for any direct, indirect, incidental,
              special, consequential, punitive, extraordinary, exemplary or other damages
              whatsoever, that result from any Third Party Services or your contractual relationship
              with any Third Party Provider, including any Expert. These limitations shall apply
              even if TaoTaro has been advised of the possibility of such damages. The foregoing
              limitations shall apply to the fullest extent permitted by applicable law.
            </li>
          </ol>
        </MKTypography>
      </Card>
    </>
  );
}

export default Terms;
